import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {toast} from 'react-toastify';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { useHistory } from 'react-router-dom';


import { profileOperations } from '../../state/ducks/profile';
import { sessionOperations } from '../../state/ducks/session';

function ProfilePasswordUpdate(props) {
  const history = useHistory();
  const {
    changePassword,
    profile
  } = props;

  const [password, setPassword] = useState({
    old_password: '',
    new_password: '',
  });
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');

  const mediumRegex = new RegExp('^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})');

  const handleOldPasswordChange = (event) => {
    setPassword({ ...password, old_password: event.target.value });
  };

  const handleNewPasswordChange = (event) => {
    setPassword({ ...password, new_password: event.target.value });
  };

  const handleSubmit = (response) => {
    changePassword ({
      old_password: password.old_password,
      new_password: password.new_password, 
    })
      .then((response) => {

        //print response
        console.log(response);
        
        if (response?.status === 200) {
          //setMessage('Password Changed');
          toast.success("Password Changed")
          history.push('/profile/password');

        } else {
          setMessage('Could not set Password');
        }
      });

      console.log(response)
  };

  const isValid = () => true;


  const hasPassword = () => {

  }

  /*
  if (!mediumRegex.test(event.target.value)) {
    setError('Password strenth too low');
    return false;
  }

  if (mediumRegex.test(event.target.value)) {
    return true;
  }

  return false;
  */

  return (
    <div className="template-profile-password root">

      <Card variant="outlined">
        <div className="header-text-container">
          <Typography className="text__title text--georgia text--bold" color="textPrimary">
            Password
          </Typography>

          <Typography className="text__secure-message text--nunito" variant="body" color="textPrimary">
            All passwords are secure and encrypted
          </Typography>

          <div>
          
          {profile && profile.has_usable_password && 
            <Typography className="text__current-password text--nunito text--upper">
            Old Password
            </Typography>
          }

            <form>

            {profile?.has_usable_password &&

              <TextField
                className="form-text-field text--nunito"
                variant="outlined"
                required
                InputLabelProps={{ shrink: true }}
                id="old_password"
                value={password.old_password}
                type="password"
                onChange={handleOldPasswordChange}
              />
            }

              <Typography className="text__current-password text--nunito text--upper">New Password</Typography>

              <TextField
                className="form-text-field text--nunito"
                variant="outlined"
                required
                InputLabelProps={{ shrink: true }}
                id="confirmPassword"
                value={password.new_password}
                type="password"
                onChange={handleNewPasswordChange}
              />
            </form>

            <Button disabled={!isValid()} onClick={handleSubmit} color="primary" variant="contained" className="button">
              Save
            </Button>
            { error ? <p className="text--error">{error}</p> : <p>{' '}</p>}
            {message && <p className="text__error">{message}</p>}
          </div>
        </div>
      </Card>
    </div>
  );
}

ProfilePasswordUpdate.propTypes = {
  changePassword: PropTypes.func,
};

ProfilePasswordUpdate.defaultProps = {
  changePassword: () => {},
  profile: {
    has_usable_password: false
  }
};

const mapStateToProps = (state) => ({
  profile: state.profile.getProfile.profile,
  session: state.session.session,
  error: state.profile.getProfile.error,
  loading: state.profile.getProfile.loading,
});

const mapDispatchToProps = {
  changePassword: sessionOperations.changePassword,
  getProfile: profileOperations.getProfile,
  sendResetLink: sessionOperations.sendResetLink,
  clearError: profileOperations.clearError,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePasswordUpdate);
