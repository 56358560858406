import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import clsx from 'clsx';
import {
  CardElement, useElements, useStripe,
} from '@stripe/react-stripe-js';
import TextField from '@material-ui/core/TextField';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import Slide from '@material-ui/core/Slide';


import { profileOperations } from '../../state/ducks/profile';
import LoadingButton from '../buttons/loadingButton';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  welcome: {
    display: 'flex',
    flexDirection: 'row',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: 'none',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: '80%',
    maxWidth: '1000px',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  button: {
    backgroundColor: theme.palette.peach,
    color: '#FFF',
    position: 'absolute',
    bottom: '10px',
    fontSize: '12px',
  },
}));

function AddPaymentMethodModal(props) {
  const classes = useStyles();
  const theme = useTheme();
  const {
    profile, updateShop, handleClose, open, addPayment,
  } = props;
  const stripe = useStripe();
  const elements = useElements();
  const [postal, setPostal] = useState('');
  const [name, setName] = useState('');
  const [loading, setLoading] = useState(false);
  const [responseError, setResponseError] = useState('');

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleChange = (event) => {
    setPostal(event.target.value);
  };

  const formValid = () => {
    if (!postal || !name) {
      return false;
    }
    return true;
  };

  const handleSubmit = async (event) => {
    // Block native form submission.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    setLoading(true);

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement);

    // Use your card Element with other Stripe.js APIs
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        address: {
          postal_code: postal,
        },
        name,
      },
    });


    if(error){
      console.log(error);
      setResponseError(error);
      setLoading(false);
    }else{
      try {
        try {
          await addPayment({ payment_id: paymentMethod.id, last_four: paymentMethod.card.last4, postal });
        } catch (err) {
          setResponseError(err.response.data.message);
          setLoading(false);
          return;
        }

        await updateShop(profile.shop[0].id, { onboarding_step: 3 });

        toast.dismiss();
        setLoading(false);
    

      } catch (e) {
        setLoading(false);
      }
    }
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="add logo modal"
        aria-describedby="add logo modal"
        className={clsx(classes.modal,'modal', 'payment-method-modal')}
      >

      <Slide direction="up" in={open} mountOnEnter unmountOnExit>
        <div className={`${classes.paper} modal__wrapper`}>


          <Typography variant="h4" color="textPrimary" className="text--georgia title text--bold text--center">Whoops! We need your payment info.</Typography>

          <Typography className="text--center text--nunito text__terms pb-10">
            To continue using your
            {' '}
            <span class="text--bold">{profile?.shop[0].plan_name}</span>
            ,  please fill out the form below. Don't worry! We won't charge you until your trial is over and you can cancel anytime!
          </Typography>

          <div className="modal-container">
            <div className="stripe mt-25">

              <div className="row">
                <TextField
                  className="text__change-name mt-25"
                  onChange={handleNameChange}
                  id="outlined-basic"
                  label="Name On Card"
                  variant="outlined"
                  required
                />
                <TextField
                  className="text__change-postal mt-25 mb-25"
                  onChange={handleChange}
                  id="outlined-basic"
                  label="Postal/ZIP Code"
                  variant="outlined"
                  required
                />
              </div>

              <div className="card-element-container pb-15">
                <CardElement
                  options={{
                    hidePostalCode: true,
                    style: {
                      base: {
                        fontSize: '16px',
                        color: '#424770',
                        border: '1px solid #5e5e5e',
                        '::placeholder': {
                          color: '#aab7c4',
                          marginTop: '25px',
                          maxWidth: '300px',

                        },
                      },
                      invalid: {
                        color: '#9e2146',
                      },
                    },
                  }}
                />
              </div>
            </div>

            <LoadingButton disabled={!stripe || !formValid()} onClick={handleSubmit} color="primary" variant="contained" loading={loading} className="button__submit">
              Update Payment Method
              {' '}
              <i className="fas fa-chevron-right" />
            </LoadingButton>

            <p class="text--error">{responseError}</p>

          </div>

        </div>
      </Slide>
      </Modal>
    </div>
  );
}

AddPaymentMethodModal.propTypes = {
  profile: PropTypes.objectOf(PropTypes.any),
  updateShop: PropTypes.func,
  handleClose: PropTypes.func,
  open: PropTypes.bool,
};

AddPaymentMethodModal.defaultProps = {
  profile: {},
  updateShop: () => {},
  handleClose: () => {},
  open: false,
};

const mapStateToProps = (state) => ({
  profile: state.profile.getProfile.profile,
});

const mapDispatchToProps = {
  updateShop: profileOperations.updateShop,
  addPayment: profileOperations.addPayment,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddPaymentMethodModal);
