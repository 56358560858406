import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { CookiesProvider } from 'react-cookie';
import { Helmet } from 'react-helmet';

import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';

import { ThemeProvider } from '@material-ui/styles';
import routes from './routes';

import './App.scss';
import Main from './views/Main';
import Logo from './images/logo.png';
import Login from './views/login/Login';
import PasswordReset from './views/passwordReset';
import PasswordChange from './views/passwordReset/passwordChange';

import OnboardingMain from './views/onboarding';
import theme from './theme';

import { profileOperations } from './state/ducks/profile';

// Sentry.init({dsn: "https://9d3e62417e974cffaa59717c7d382bf6@o394189.ingest.sentry.io/5288738"});
const stripePromise = loadStripe(String(process.env.REACT_APP_STRIPE_KEY || 'pk_test_51HKzs6EzjuN8pWiu5IlHaEiXjxFhhddYQVNsKlvICXoY04sCK02kZdRlgcBOt3EkErnSO6G1ma2BApBNQvJtW6cr00ivQWxAXA'));
// ReactGA.initialize('G-6SZ69EFV46');

const PrivateRoute = ({ component: Component, ...rest }) => {
  const session = useSelector((state) => state.session.session);
  const profile = useSelector((state) => state.profile.getProfile);

  const isLoggedIn = () => {
    if (session.isLoggedIn) {
      return true;
    }
    return false;
  };

  return (

    <Route
      {...rest}
      render={(props) => (
        isLoggedIn()
          ? (
            <Main>
              <Component {...props} />
              {' '}
              <Helmet>
                <title>
                  {rest.name.length > 0 ? rest.name : 'admin'}
                  {' '}
                  - blanka
                </title>
              </Helmet>
            </Main>
          )
          : <Redirect to="/login" />
      )}
    />
  );
};

const PublicRoute = ({ component: Component, restricted, ...rest }) => {
  const session = useSelector((state) => state.session.session);

  const isLoggedIn = () => {
    if (session.isLoggedIn) {
      return true;
    }
    return false;
  };

  return (
  // restricted = false meaning public route
  // restricted = true meaning restricted route
    <Route
      {...rest}
      render={(props) => (
        isLoggedIn()
          ? <Redirect to="/" />
          : <Component {...props} />
      )}
    />
  );
};

function App(props) {
  const dispatch = useDispatch();
  const session = useSelector((state) => state.session.session);
  const profile = useSelector((state) => state.profile.getProfile);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch(profileOperations.getProfile()).then((response) => {
      console.log('profile set');
    }).catch((err) => {
      console.log('error retrieving profile');
    });

    // add an delay to allow for loading and to prevent quick flashes between screens
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  const appLoaded = () => {
    if ((session.loading && profile.loading) || loading) {
      return false;
    }

    return true;
  };

  return (
    <Router>
      <CookiesProvider>
        <Elements stripe={stripePromise}>
          <div className="App">

            <ToastContainer
              position="top-center"
              autoClose={3000}
              hideProgressBar
              newestOnTop={false}
              closeOnClick
              limit={2}
              rtl={false}
              pauseOnFocusLoss
              pauseOnHover
            />

            <ThemeProvider theme={theme}>

              { !appLoaded()
              && (
              <main className="loading-screen">
                <img alt="Logo" src={Logo} style={{ width: '200px', padding: '10px', margin: '0 auto' }} />
                <LinearProgress style={{ width: '175px' }} />
              </main>
              )}

              <Route exact path="/login" to="/login" component={Login} />
              <Route restricted={false} exact path="/signup" to="/signup" component={OnboardingMain} />
              <Route restricted exact path="/password-reset" to="/password-reset" component={PasswordReset} />
              <Route restricted exact path="/password-set" to="/password-set" component={PasswordChange} />

              <Switch>
                {routes.map((route, index) => (
                  <PrivateRoute key={index} path={route.path} name={route.name} component={route.component} exact={route.exact} />
                ))}
              </Switch>

            </ThemeProvider>
          </div>
        </Elements>
      </CookiesProvider>
    </Router>
  );
}

/*

            <Route path='*'>
              <Login></Login>
            </Route>

*/

/*
{(!session.isLoggedIn && !loading)
  && //<Redirect to="/login" />
} */

export default App;
