import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import Typography from '@material-ui/core/Typography';
import LoadingButton from '../../../components/buttons/loadingButton';

function ShopifyOnboardingStepOne(props) {
  const {
    profile, nextStep, loading,
  } = props;

  return (
    <div className="modal__step-1">
      <div className="welcome-text-box pt-50">
        <Typography className="pb-10 text--georgia text--bold welcome__name" color="secondary">
          Hey
          {' '}
          {profile.name}
          !
        </Typography>

        <Typography className="text__signup-thank-you" variant="body2" color="secondary">
          Thanks for signing up. In just a few short clicks you will be on your way to building your brand.
        </Typography>
      </div>

      <LoadingButton loading={loading} onClick={nextStep} color="primary" variant="contained" className="button__initial-step">
        Next Step
        {' '}
        <i className="fas fa-chevron-right" />
      </LoadingButton>

    </div>
  );
}

ShopifyOnboardingStepOne.propTypes = {
  profile: PropTypes.objectOf(PropTypes.any),
  nextStep: PropTypes.func,
  loading: PropTypes.bool,
};

ShopifyOnboardingStepOne.defaultProps = {
  profile: {},
  nextStep: () => {},
  loading: false,
};

const mapStateToProps = (state) => ({
  session: state.session.session,
  profile: state.profile.getProfile.profile,
});

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(ShopifyOnboardingStepOne);
