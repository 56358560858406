
export const ADD_TO_CART = "cart/ADD_TO_CART";
export const REMOVE_FROM_CART = "cart/REMOVE_FROM_CART";
export const CLEAR_CART = "cart/CLEAR_CART";
export const UPDATE_CART = "cart/UPDATE_CART";
export const CHECKOUT_CART = "cart/CHECKOUT_CART";

export const CART_UPDATE_SHIPPING = "cart/CART_UPDATE_SHIPPING";


export const SAMPLES_ADD_TO_CART = "cart/SAMPLES_ADD_TO_CART ";
export const SAMPLES_REMOVE_FROM_CART = "cart/SAMPLES_REMOVE_FROM_CART";
export const SAMPLES_CLEAR_CART = "cart/SAMPLES_CLEAR_CART";
export const SAMPLES_UPDATE_CART = "cart/SAMPLES_UPDATE_CART";
export const SAMPLES_CHECKOUT_CART = "cart/SAMPLES_CHECKOUT_CART";


export const SAMPLE_ORDER = "cart/SAMPLE_ORDER";
export const SAMPLE_ORDER_SUCCESS = "cart/SAMPLE_ORDER_SUCCESS";
export const SAMPLE_ORDER_FAILED = "cart/SAMPLE_ORDER_FAILED";


export const ORDER_BRAND_PLATE = "cart/ORDER_BRAND_PLATE";
export const ORDER_BRAND_PLATE_SUCCESS = "cart/ORDER_BRAND_PLATE_SUCCESS";
export const ORDER_BRAND_PLATE_FAILED = "cart/ORDER_BRAND_PLATE_FAILED";