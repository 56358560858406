import PropTypes from 'prop-types';
import React, { useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { useDropzone } from 'react-dropzone';
import clsx from 'clsx';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';

import { profileOperations } from '../../state/ducks/profile';
import LoadingButton from '../buttons/loadingButton';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  welcome: {
    display: 'flex',
    flexDirection: 'row',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: 'none',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: '80%',
    maxWidth: '1000px',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  button: {
    backgroundColor: theme.palette.peach,
    color: '#FFF',
    position: 'absolute',
    bottom: '10px',
    fontSize: '12px',
  },
}));

function AddLogoModal(props) {
  const classes = useStyles();
  const theme = useTheme();
  const {
    profile, updateShop, handleClose, open,
  } = props;
  const [preview, setPreview] = useState();
  const [uploaded, setUploaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [files, setFiles] = useState();
  const [error, setError] = useState();
  const maxSize = 1048576 * 2; // 2mb

  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      setFiles(acceptedFiles[0]);
      setPreview(URL.createObjectURL(acceptedFiles[0]));
    }
  }, []);

  const {
    getRootProps, getInputProps, isDragActive, isDragReject, rejectedFiles, fileRejections,
  } = useDropzone({
    accept: 'image/*',
    onDrop,
    minSize: 0,
    maxSize,
  });

  const bytesToMegaBytes = (bytes) => bytes / (1024 * 1024);

  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <li key={file.path}>
      {file.path}
      {' '}
      -
      {' '}
      {bytesToMegaBytes(file.size).toFixed(2)}
      {' '}
      mb
      <ul>
        {errors.map((e) => (
          <li key={e.code}>{e.message}</li>
        ))}
      </ul>
    </li>
  ));

  const handleUpload = () => {
    setIsLoading(true);
    updateShop(profile.shop[0].id, { logo: files, logo_uploaded: true })
      .then((response) => {
        if (response.status === 200) {
          handleClose();
          setIsLoading(false);
        } else {
          setError('An error occured while uplaoding your logo, please contact us');
        }
        toast.success('Logo Uploaded');
        setIsLoading(false);
      })
      .catch((err) => {
        setError('An error occured while uplaoding your logo, please contact us');
        setIsLoading(false);
      });
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="add logo modal"
        aria-describedby="add logo modal"
        className={clsx(classes.modal, 'add-logo-modal')}
      >

        <div className={`${classes.paper} add-logo-container`}>

          <Typography onClick={handleClose} className="close-button modal__close">[x] close</Typography>

          <Typography variant="h4" color="textSecondary" className="text--georgia title text--bold text--center">Upload Your Logo</Typography>

          <div className="modal-container">

            <div className="modalLeft">
              <div className={classes.imgContainer}>

                {!files
                                && (
                                <div {...getRootProps()} className="dropzone">
                                  <input {...getInputProps()} />
                                  {
                                        isDragActive
                                          ? <p>Drop the files here ...</p>
                                          : <p>Drag 'n' drop your logo here, or click to select</p>
                                    }
                                  {isDragActive && !isDragReject && "Drop it like it's hot!"}
                                  {isDragReject && 'File type not accepted, sorry!'}
                                </div>
                                )}

                {files
                                && (
                                <img
                                  alt="logo"
                                  src={preview}
                                  className={classes.img}
                                />
                                )}

                <ul>{fileRejectionItems}</ul>
                <p className="text--error">{error}</p>

              </div>

            </div>
            <div className="modalRight">

              <ul>
                <li>Accepted File Formats: PNG,SVG</li>
                <li>Logo will be centered within max area</li>
                <li>File size limit: 2mb</li>
                <li>Logo must be 1 color</li>
              </ul>

              <LoadingButton loading={isLoading} onClick={handleUpload} disabled={!files} color="primary" variant="contained">Upload</LoadingButton>

              {uploaded
                            && <Typography>File uploaded succesfully</Typography>}

            </div>

          </div>

        </div>

      </Modal>
    </div>
  );
}

AddLogoModal.propTypes = {
  profile: PropTypes.objectOf(PropTypes.any),
  updateShop: PropTypes.func,
  handleClose: PropTypes.func,
  open: PropTypes.bool,
};

AddLogoModal.defaultProps = {
  profile: {},
  updateShop: () => {},
  handleClose: () => {},
  open: false,
};

const mapStateToProps = (state) => ({
  // profile: state.profile.getProfile.profile,
});

const mapDispatchToProps = {
  updateShop: profileOperations.updateShop,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddLogoModal);
