import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import Paper from '@material-ui/core/Paper';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Chip from '@material-ui/core/Chip';

import moment from 'moment';

const UNFULFILLED = 'UNFULFILLED';
const FULFILLED = 'FULFILLED';
const CANCELLED = 'CANCELLED';
const PAYMENT_ERROR = 'PAYMENT_ERROR';

function OrderItem(props) {
    const {order} = props;

    const getStatus = (status) =>{
        switch(status){
          case UNFULFILLED:
            return 'secondary';
          case FULFILLED:
            return 'primary';
          case CANCELLED:
            return 'default';
          case PAYMENT_ERROR:
            return 'error';
          default:
            return ''
        }
    }

    const calcLineItemTotal = (price,quantity) => {
      return (price * quantity).toFixed(2);
    }

    return (
        <Card className="order-card root">
          <CardContent container>
            <div className="order-card__header">
              <Typography className="text--header text--georgia text--bold">Order #{order.shopify_order_number}</Typography>
              <Typography className="text--header text--georgia text--bold">Date: {moment(order.created_at).format("DD-MM-yyy")}</Typography>

              <div className="order-card__status">
                <Typography variant="h5" className="text--header text--georgia text--bold">Status: </Typography>
                <Chip label={order.status} color={getStatus(order.status)} className={getStatus(order.status)} />
              </div>

            </div>

            <div className="order-card__details" xs={12} md={12}>

              <TableContainer component={Paper}>
                <Table aria-label="">
                  <TableHead>
                    <TableRow>
                      <TableCell>SKU</TableCell>
                      <TableCell>Product</TableCell>
                      <TableCell align="right">Quantity</TableCell>
                      <TableCell align="right">Price</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {order?.order_items.map((item)=>(
                      <TableRow>
                        <TableCell className="text--cell-details text--nunito text--upper">{item.sku}</TableCell>
                        <TableCell className="text--cell-details text--nunito text--upper">{item.title}</TableCell>
                        <TableCell className="text--cell-details" align="right">{item.quantity}</TableCell>
                        <TableCell className="text--cell-details" align="right">{calcLineItemTotal(item.price,item.quantity)}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </CardContent>
      </Card>
    );
}


export default OrderItem;