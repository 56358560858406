import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import clsx from 'clsx';
import {
  CardElement, useElements, useStripe,
} from '@stripe/react-stripe-js';
import Checkbox from '@material-ui/core/Checkbox';
import { FormGroup, FormControl, FormControlLabel } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import Slide from '@material-ui/core/Slide';
import { profileOperations } from '../../state/ducks/profile';
import { SHOP_ONBOARDING_SHOPIFY, PLAN_FREE_ID } from '../../constants';
import {notifications } from '../../components/notifications';
import LoadingButton from '../buttons/loadingButton';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: 'none',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: 'auto',
    maxWidth: '1000px',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
}));

function PaymentFailedModal(props) {
  const classes = useStyles();
  const history = useHistory();
  const stripe = useStripe();
  const elements = useElements();
  const [postal, setPostal] = useState('');
  const [downgradeLoading, setDowngradeLoading] = useState('');
  const [agreed, setAgreed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [responseError, setResponseError] = useState('');
  const [name, setName] = useState('');
  const {
    handleClose, open, updatePlan, profile, getProfile,updateShop,addPayment
  } = props;


  const formValid = () => {
    if (!postal || !name) {
      return false;
    }
    return true;
  };

  const handlePaid = (id) => {
    // check if user has their billing details set
    if(!profile?.shop[0].billing_address) {
      //history.push('/profile/billing?needs_update=true');
      notifications.noBillingMethodError();

      return;
    }

    // if billing details are set upgrade plan
    updatePlan(id).then((response) => {
      if (response && response.status === 200 && profile.shop[0] && profile.shop[0].shop_signup_type === SHOP_ONBOARDING_SHOPIFY) {
        window.location.href = response.data.confirmation_url;
      } else if (response && response.status === 200 && profile.shop[0]) {
        getProfile().then((response) => {
          toast.success('Success! Your account has been updated');
          handleClose();
        });
      }
    });
  };

  const handleDowngradePlan = () =>{

    updatePlan(PLAN_FREE_ID)
      .then(() => {
        getProfile();
       toast.success('Success! Your account has been updated');
      });

  }


    const handleSubmit = async (event) => {
    // Block native form submission.
        event.preventDefault();

        if (!stripe || !elements) {
        // Stripe.js has not loaded yet. Make sure to disable
        // form submission until Stripe.js has loaded.
        return;
        }

        setLoading(true);

        // Get a reference to a mounted CardElement. Elements knows how
        // to find your CardElement because there can only ever be one of
        // each type of element.
        const cardElement = elements.getElement(CardElement);

        // Use your card Element with other Stripe.js APIs
        const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: {
            address: {
            postal_code: postal,
            },
            name,
        },
        });


        if(error){
            console.log(error);
            setResponseError(error);
            setLoading(false);
        }else{
            try {
                try {
                    await addPayment({ payment_id: paymentMethod.id, last_four: paymentMethod.card.last4, postal });
                } catch (err) {
                    setResponseError(err.response.data.message);
                    setLoading(false);
                return;
                }

                await updateShop(profile.shop[0].id, { onboarding_step: 3 });
                
                toast.success('Success! Your account has been updated');
                setLoading(false);
                getProfile();
            

            } catch (e) {
                setLoading(false);
            }
        }
    };



  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="Payment Failed Modal"
        aria-describedby="Payment Failed Modal"
        className={clsx(classes.modal, 'payment-method-modal')}
      >
        <Slide direction="up" in={open} mountOnEnter unmountOnExit>
            <div className={`${classes.paper} modal__wrapper`}>

                <div className="text--center ">
                  <Typography className="text--bold text__title" color="secondary">Whoops! Your payment information needs an update.</Typography>
                  <Typography variant="body" color="secondary">Your payment failed. To continue using
                  {' '}
                  <span class="text--bold">{profile?.shop[0].plan_name}</span>
                  ,  please fill out the form below.
                  </Typography>
                </div>

             
                <div className="modal-container">
                    <div className="stripe mt-25">

                    <div className="row">
                        <TextField
                        className="text__change-name mt-25"
                        onChange={(event)=>setName(event.target.value)}
                        id="outlined-basic"
                        label="Name On Card"
                        variant="outlined"
                        required
                        />
                        <TextField
                        className="text__change-postal mt-25 mb-25"
                        onChange={(event)=>setPostal(event.target.value)}
                        id="outlined-basic"
                        label="Postal/ZIP Code"
                        variant="outlined"
                        required
                        />
                    </div>

                    <div className="card-element-container pb-15">
                        <CardElement
                        options={{
                            hidePostalCode: true,
                            style: {
                            base: {
                                fontSize: '16px',
                                color: '#424770',
                                border: '1px solid #5e5e5e',
                                '::placeholder': {
                                color: '#aab7c4',
                                marginTop: '25px',
                                maxWidth: '300px',

                                },
                            },
                            invalid: {
                                color: '#9e2146',
                            },
                            },
                        }}
                        />
                    </div>
                    </div>

                    <LoadingButton disabled={!stripe || !formValid()} onClick={handleSubmit} color="primary" variant="contained" loading={loading} className="button__submit">
                    Submit
                    {' '}
                    <i className="fas fa-chevron-right" />
                    </LoadingButton>

                    <p class="text--error">{responseError}</p>

                    <Typography className="text--bold " variant="h6" color="secondary">Want to downgrade your plan?</Typography>
                    <Typography variant="body" color="secondary">Agree to the terms below, and you will be moved to the Free plan.</Typography>

                    <FormGroup className="form-group">
                      <FormControlLabel className="text--sub" control={<Checkbox checked={agreed} onChange={(e)=>setAgreed(!agreed)} inputProps={{ 'aria-label': 'controlled' }} />} label="By checking this box, you agree to downgrade to the Free 
                        PLAN and all of your custom products will be removed from your account." />
                    
                    </FormGroup>

                    <LoadingButton disabled={!agreed} onClick={handleDowngradePlan} color="primary" variant="contained" loading={downgradeLoading} className="button__submit button__downgrade">
                    Downgrade Plan
                    </LoadingButton>
                    

                </div>
                
            </div>
        </Slide>
      </Modal>
    </div>
  );
}

PaymentFailedModal.propTypes = {
  handleClose: PropTypes.func,
  open: PropTypes.bool,
  updatePlan: PropTypes.func,
};

PaymentFailedModal.defaultProps = {
  handleClose: () => {},
  open: false,
  updatePlan: () => {},
};

const mapStateToProps = (state) => ({
  profile: state.profile.getProfile.profile,
});

const mapDispatchToProps = {
  getProfile: profileOperations.getProfile,
  updatePlan: profileOperations.updatePlan,
  updateShop: profileOperations.updateShop,
  addPayment: profileOperations.addPayment,
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentFailedModal);
