import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import clsx from 'clsx';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';

import { profileOperations } from '../../state/ducks/profile';
import { SHOP_ONBOARDING_SHOPIFY } from '../../constants';
import {notifications } from '../../components/notifications';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: 'none',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: 'auto',
    maxWidth: '1000px',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
}));

function FreePlanLimitModal(props) {
  const classes = useStyles();
  const history = useHistory();
  const {
    handleClose, open, updatePlan, profile, getProfile,
  } = props;

  const handlePaid = (id) => {
    // check if user has their billing details set
    if(!profile?.shop[0].billing_address) {
      //history.push('/profile/billing?needs_update=true');
      notifications.noBillingMethodError();

      return;
    }

    // if billing details are set upgrade plan
    updatePlan(id).then((response) => {
      if (response && response.status === 200 && profile.shop[0] && profile.shop[0].shop_signup_type === SHOP_ONBOARDING_SHOPIFY) {
        window.location.href = response.data.confirmation_url;
      } else if (response && response.status === 200 && profile.shop[0]) {
        // TODO: check to see if upgraded or downgraded
        getProfile().then((response) => {
          toast.success('Success! Your account has been updated');
          handleClose();
        });
      }
    });
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="Free Plan Limit Modal"
        aria-describedby="Free Plan Limit Modal"
        className={clsx(classes.modal, 'plan-limit-modal')}
      >

        <div className={classes.paper}>

          <Typography className="text--bold text__title" color="secondary">Whoops! You’ve reached your FREE plan limit.</Typography>

          <Typography onClick={handleClose} className="close-button modal-close-button">[x] close</Typography>

          <p className="text__body">
            Sorry! You need to be on the
            {' '}
            <span className="text--bold">Growth Plan</span>
            {' '}
            or
            {' '}
            <span className="text--bold">Scale Plan</span>
            {' '}
            to brand products.
          </p>

          <p className="text--bold text__try-free">
            Try a 14-day trial for free now!
          </p>

          <div className="modal__plans">

            <Card className="payment_price_item mb-10 center">
              <CardContent>
                <Typography variant="h4" className="text--bold text--georgia text--center" color="textSecondary" gutterBottom>
                  $29/mo
                </Typography>
                <Typography className="text__plan-name text--bold text--georgia text--center" color="textSecondary" gutterBottom>
                  GROWTH PLAN
                </Typography>

                <ul>
                  <li>10 private label products</li>
                  <li>Unlimited unbranded products</li>
                  <li>Free product mock-ups</li>
                </ul>

              </CardContent>
              <CardActions>
                <Button className="try-free-button" onClick={() => handlePaid(2)} color="primary" variant="contained">
                  TRY FOR FREE
                  {' '}
                  <i className="fas fa-chevron-right" />
                </Button>
              </CardActions>
            </Card>

            <Card className="payment_price_item mb-10 center">
              <CardContent>
                <Typography variant="h4" className="text--bold text--georgia text--center" color="textSecondary" gutterBottom>
                  $59/mo
                </Typography>
                <Typography className="text__plan-name text--bold text--georgia text--center" color="textSecondary" gutterBottom>
                  SCALE PLAN
                </Typography>

                <ul>
                  <li>Unlimited private label products</li>
                  <li>Unlimited unbranded products</li>
                  <li>Free product mock-ups</li>
                  <li>Dedicated account manager</li>
                </ul>

              </CardContent>
              <CardActions>
                <Button className="try-free-button" onClick={() => handlePaid(3)} color="primary" variant="contained">
                  TRY FOR FREE
                  {' '}
                  <i className="fas fa-chevron-right" />
                </Button>
              </CardActions>
            </Card>

          </div>

        </div>

      </Modal>
    </div>
  );
}

FreePlanLimitModal.propTypes = {
  handleClose: PropTypes.func,
  open: PropTypes.bool,
  updatePlan: PropTypes.func,
};

FreePlanLimitModal.defaultProps = {
  handleClose: () => {},
  open: false,
  updatePlan: () => {},
};

const mapStateToProps = (state) => ({
  profile: state.profile.getProfile.profile,
});

const mapDispatchToProps = {
  updateShop: profileOperations.updateShop,
  getProfile: profileOperations.getProfile,
  updatePlan: profileOperations.updatePlan,
};

export default connect(mapStateToProps, mapDispatchToProps)(FreePlanLimitModal);
