import { combineReducers } from "redux";
import * as types from "./types";

function getOrders(state = {orders:[], loading:false, error: false}, action) {
    switch (action.type) {
        case types.FETCH_LIST:
            return { ...state, loading: true,error: false};
        case types.FETCH_LIST_SUCCESS:
            return { ...state, loading: false, orders:action.data };
        case types.FETCH_LIST_FAILED:
            return {
                ...state,
                loading: false,
                error: true,
            };
        case types.CLEAR_FETCH_LIST_ERROR:
            return {
                ...state,
                error: false,
            };
        default:
            return state;
    }
}


function inventoryOrders(state = {orders:[], loading:false, error: false}, action) {
    switch (action.type) {
        case types.FETCH_LIST:
            return { ...state, loading: true,error: false};
        case types.FETCH_INVENTORY_ORDERS_SUCCESS:
            return { ...state, loading: false, orders:action.payload.data };
        case types.FETCH_INVENTORY_ORDERS_FAILED:
            return {
                ...state,
                loading: false,
                error: true,
            };
        default:
            return state;
    }
}


function updateOrder(state = {order:{}, loading:false, success: false}, action) {
    switch (action.type) {
        case types.UPDATE_ORDER:
            return { ...state, loading: true,};
        case types.UPDATE_ORDER_SUCCESS:
            return { ...state, loading: false, order:action.data, success:true };
        case types.UPDATE_ORDER_ERROR:
            return {
                ...state,
                loading: false,
                error: 'An error occured while updating the order'
            };
        default:
            return state;
    }
}


export default combineReducers({
    getOrders,
    updateOrder,
    inventoryOrders
});
