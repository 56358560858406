import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import { profileOperations } from '../../../state/ducks/profile';

function ShopifyOnboardingStepThree(props) {
  const {
    profile, updateShop, updatePlan, nextStep,
  } = props;

  const handleFree = (id) => {
    updateShop(profile.shop[0].id, { onboarding_step: 3, plan: id })
      .then(() => {
        nextStep();

        window.location.href = '/?charge_approved=true';
        // handleClose();
      });
  };

  const handlePaid = (id) => {
    updateShop(profile.shop[0].id, { onboarding_step: 2 })
      .then(() => {
        updatePlan(id).then((response) => {
          if (response && response.status === 200) {
            window.location.href = response.data.confirmation_url;
          }
        });
      });
  };

  return (
    <div>

      <Typography className="text--bold text--nunito text__try-free-plan">Try any plan free for 14 days!</Typography>

      <Typography className="text--bold text__pick-plan text--georgia" variant="h4" color="secondary">Pick Your Plan</Typography>

      <div className="plans">

        <Card className="payment_price_item mb-10">
          <CardContent>
            <Typography variant="h4" className="text--bold text--title text--georgia" color="textSecondary" gutterBottom>
              $0/mo
            </Typography>
            <Typography variant="h5" className="text--bold text--title text--georgia" color="textSecondary" gutterBottom>
              FREE PLAN
            </Typography>

            <ul className="pt-20 flex-column-center">
              <li>0 private label products</li>
              <li>10 unbranded products</li>
            </ul>

          </CardContent>
          <CardActions>
            <Button onClick={() => handleFree(1)} color="primary" variant="contained" className="button__plans">
              Get Started
              {' '}
              <i className="fas fa-chevron-right" />
            </Button>
          </CardActions>
        </Card>

        <Card className="payment_price_item mb-10 center">
          <CardContent>
            <Typography variant="h4" className="text--bold text--title text--georgia " color="textSecondary" gutterBottom>
              $29/mo
            </Typography>
            <Typography variant="h5" className="text--bold text--title text--georgia" color="textSecondary" gutterBottom>
              GROWTH PLAN
            </Typography>

            <ul className="pt-20 flex-column-center">
              <li>10 private label products</li>
              <li>Unlimited unbranded products</li>
              <li>Free product mock-ups</li>
            </ul>

          </CardContent>
          <CardActions>
            <Button onClick={() => handlePaid(2)} color="primary" variant="contained" className="button__plans">
              Try For free
              {' '}
              <i className="fas fa-chevron-right" />

            </Button>
          </CardActions>
        </Card>

        <Card className="payment_price_item mb-10">
          <CardContent>
            <Typography variant="h4" className="text--bold text--title text--georgia" color="textSecondary" gutterBottom>
              $59/mo
            </Typography>
            <Typography variant="h5" className="text--bold text--title text--georgia" color="textSecondary" gutterBottom>
              SCALE PLAN
            </Typography>

            <ul className="pt-20 flex-column-center">
              <li>Unlimited private label products</li>
              <li>Unlimited unbranded products products</li>
              <li>Free product mock-ups</li>
              <li>Dedicated account manager</li>
            </ul>

          </CardContent>
          <CardActions>
            <Button onClick={() => handlePaid(3)} color="primary" variant="contained" className="button__try-free">
              Try For free
              {' '}
              <i className="fas fa-chevron-right" />
            </Button>
          </CardActions>
        </Card>

      </div>

    </div>
  );
}

ShopifyOnboardingStepThree.propTypes = {
  profile: PropTypes.objectOf(),
  updateShop: PropTypes.func,
  updatePlan: PropTypes.func,
  nextStep: PropTypes.func,
  handleClose: PropTypes.func,
};

ShopifyOnboardingStepThree.defaultProps = {
  profile: {},
  updateShop: () => {},
  updatePlan: () => {},
  nextStep: () => {},
  handleClose: () => {},
};

const mapStateToProps = (state) => ({
  profile: state.profile.getProfile.profile,
});

const mapDispatchToProps = {
  getProfile: profileOperations.getProfile,
  updateShop: profileOperations.updateShop,
  updatePlan: profileOperations.updatePlan,
};

export default connect(mapStateToProps, mapDispatchToProps)(ShopifyOnboardingStepThree);
