import React, { useEffect, useState } from 'react';
import { useSelector, connect } from 'react-redux';
import {
  Redirect,
} from 'react-router-dom';
import {Helmet} from "react-helmet";
import { SHOP_ONBOARDING_STANDALONE, SHOP_ONBOARDING_SHOPIFY } from '../../constants';

import StandaloneOnboarding from './standalone';
import ShopifyOnboarding from './shopify';

function OnboardingMain(props) {
  const profile = useSelector((state) => state.profile.getProfile.profile);
  const session = useSelector((state) => state.session.session);
  const [activeStep, setActiveStep] = useState(0);
  const [open, setOpen] = useState(false);

  /*
  useEffect(() => {
    
    if(session.isLoggedIn){
      return <Redirect to="/"></Redirect>
    }

  }, [session]);
  */

  const getStep = () => {
    if(profile && profile?.shop[0].onboarding_step){
      return profile?.shop[0].onboarding_step;
    }
    return 0;

  }

  const getOnboardingType = () =>{
    
    if(profile && profile?.shop[0].shop_signup_type == SHOP_ONBOARDING_SHOPIFY){
      return <ShopifyOnboarding/>
    }
    return <StandaloneOnboarding  />
  }
  

  const isOnboardingComplete = (profile) =>{

    //if

  }
  
  //if(session.isLoggedIn){
  //  return <Redirect to="/"></Redirect>
  //}

  if (!session.loading && session.isLoggedIn && profile?.shop[0]?.onboarding_complete ) {
    return <Redirect to="/" />;
  }

  return (
    <>
      <Helmet>     
        <title>signup - blanka</title>
        <meta name="description" content="Try for free! Private label dropshipping app for Shopify, WooCommerce and more"/>
      </Helmet>

  
      {getOnboardingType()}
    </>
  );
}



const mapStateToProps = (state) => ({
  //profile: state.profile.getProfile.profile,
  session: state.session.session,
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingMain);
