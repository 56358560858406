import PropTypes from 'prop-types';
import React from 'react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

function ProgressStepper(props) {
  const { activeStep, steps } = props;

  const isActive = (step) => {
    if (step === activeStep) {
      return true;
    }

    return false;
  };

  return (
    <Stepper activeStep={activeStep}>
      {steps.map((label, index) => {
        const stepProps = {};
        const labelProps = {};

        return (
          <Step key={label} {...stepProps} active={isActive(index)}>
            <StepLabel {...labelProps}>{label}</StepLabel>
          </Step>
        );
      })}
    </Stepper>
  );
}

ProgressStepper.defaultProps = {
  activeStep: 0,
  steps: [],
};

ProgressStepper.propTypes = {
  activeStep: PropTypes.number,
  steps: PropTypes.arrayOf,
};

export default ProgressStepper;
