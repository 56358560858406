import * as types from './types';
import * as appTypes from '../app/types';
import { apiService } from '../../middlewares';

export function getSupplierProducts() {
  return (dispatch) => {
    dispatch({
      type: types.FETCH_SUPPLIER_PRODUCTS,
    });

    dispatch({ type: appTypes.IS_LOADING, payload: true });

    return apiService.get('/supplier-products/')
      .then((response) => {
        dispatch({
          type: types.FETCH_SUPPLIER_PRODUCTS_SUCCESS,
          data: response.data,
        });
        dispatch({ type: appTypes.IS_LOADING, payload: false });
        return response;
      })
      .catch((error) => {
        dispatch({ type: appTypes.IS_LOADING, payload: false });
        dispatch({
          type: types.FETCH_SUPPLIER_PRODUCTS_FAILED,
          isLoading: false,
          error,
        });
      });
  };
}

export function getBlankProducts() {
  return (dispatch) => {
    dispatch({
      type: types.FETCH_BLANK_PRODUCTS,
    });

    dispatch({ type: appTypes.IS_LOADING, payload: true });

    return apiService.get('/products/?product_type=BLANK')
      .then((response) => {
        dispatch({
          type: types.FETCH_BLANK_PRODUCTS_SUCCESS,
          data: response.data,
        });
        dispatch({ type: appTypes.IS_LOADING, payload: false });
        return response;
      })
      .catch((error) => {
        dispatch({ type: appTypes.IS_LOADING, payload: false });
        dispatch({
          type: types.FETCH_BLANK_PRODUCTS_FAILED,
          isLoading: false,
          error,
        });
      });
  };
}

export function getCustomProducts() {
  return (dispatch) => {
    dispatch({
      type: types.FETCH_CUSTOM_PRODUCTS,
    });

    dispatch({ type: appTypes.IS_LOADING, payload: true });

    return apiService.get('/products/?product_type=CUSTOM')
      .then((response) => {
        dispatch({
          type: types.FETCH_CUSTOM_PRODUCTS_SUCCESS,
          data: response.data,
        });
        dispatch({ type: appTypes.IS_LOADING, payload: false });
        return response;
      })
      .catch((error) => {
        dispatch({ type: appTypes.IS_LOADING, payload: false });
        dispatch({
          type: types.FETCH_CUSTOM_PRODUCTS_FAILED,
          isLoading: false,
          error,
        });
      });
  };
}

export function getMyProducts() {
  return (dispatch) => {
    dispatch({ type: appTypes.IS_LOADING, payload: true });

    return apiService.get('/shop-products/')
      .then((response) => {
        dispatch({
          type: types.FETCH_MY_PRODUCTS_SUCCESS,
          data: response.data,
        });
        dispatch({ type: appTypes.IS_LOADING, payload: false });

        return response;
      })
      .catch((error) => {
        dispatch({ type: appTypes.IS_LOADING, payload: false });
        dispatch({
          type: types.FETCH_MY_PRODUCTS_FAILED,
          isLoading: false,
          error,
        });
      });
  };
}

export function addToStore(id) {
  return (dispatch) => apiService.post('/shop-product/add-product/', { product_id: id })
    .then((response) => {
      dispatch({
        type: types.ADD_PRODUCT_TO_STORE_SUCCESS,
        data: response.data,
      });
      return response;
    })
    .catch((error) => {
      dispatch({
        type: types.ADD_PRODUCT_TO_STORE_FAILED,
        isLoading: false,
        error,
      });
    });
}

export function addInventory(items) {
  return (dispatch) => apiService.post('/shop-product/add-inventory/', items)
    .then((response) => {
      dispatch({
        type: types.ADD_INVENTORY_SUCCESS,
        data: response.data,
      });
      return response;
    })
    .catch((error) => {
      dispatch({
        type: types.ADD_INVENTORY_FAILED,
        isLoading: false,
        error,
      });
      return error;
    });
}

export function publishToStore(id) {
  return (dispatch) => apiService.post('/shop/publish_product/', { product_id: id })
    .then((response) => {
      dispatch({
        type: types.PUBLISH_PRODUCT_SUCCESS,
        data: response.data,
      });
      return response;
    })
    .catch((error) => {
      dispatch({
        type: types.PUBLISH_PRODUCT_FAILED,
        isLoading: false,
        error,
      });
      throw error;
    });
}

export function unpublishProduct(id) {
  return (dispatch) => apiService.post('/shop/unpublish_product/', { product_id: id })
    .then((response) => {
      dispatch({
        type: types.UNPUBLISH_PRODUCT_SUCCESS,
        data: response.data,
      });
      return response;
    })
    .catch((error) => {
      dispatch({
        type: types.UNPUBLISH_PRODUCT_FAILED,
        isLoading: false,
        error,
      });
      throw error;
    });
}
