import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Switch, Route, NavLink,
} from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { profileOperations } from '../../state/ducks/profile';

import ProfilePlan from './profilePlan';
import ProfileBilling from './profileBilling';
import ProfileStore from './profileStore';
import ProfilePassword from './profilePassword';
import ProfileBillingUpdate from './profileBillingUpdate';
import ProfilePasswordUpdate from './profilePasswordUpdate';

function ProfileMain(props) {
  const { getProfile, profile } = props;

  useEffect(() => {
    async function fetchData() {
      await getProfile();
    }

    if (!profile) {
      fetchData();
    }
  }, []);

  return (
    <div className="root template-profile">
      <Grid className="header" container spacing={2}>

        <Typography variant="h6">My Account</Typography>

      </Grid>
      <Grid className="paper" container spacing={1}>
        <div className="nav-div">
          <NavLink exact className="text__card-navigation" activeClassName="text__is-active" to="/profile">
            Plan
          </NavLink>
          {'\xa0\xa0  |  \xa0\xa0'}
          <NavLink className="text__card-navigation" activeClassName="text__is-active" to="/profile/billing">
            Payment
          </NavLink>
          {'\xa0\xa0  |  \xa0\xa0'}
          <NavLink className="text__card-navigation" activeClassName="text__is-active" to="/profile/password">
            Password
          </NavLink>
          {'\xa0\xa0  |  \xa0\xa0'}
          <NavLink className="text__card-navigation" activeClassName="text__is-active" to="/profile/connect">
            My Store
          </NavLink>
        </div>
      </Grid>
      <Grid className="paper" container spacing={1}>

        <Switch>
          <Route path="/profile" exact>
            <ProfilePlan profile={profile} />
          </Route>
          <Route path="/profile/billing" exact>
            <ProfileBilling profile={profile} />
          </Route>
          <Route path="/profile/billing/update" exact>
            <ProfileBillingUpdate profile={profile} />
          </Route>

          <Route path="/profile/password" exact>
            <ProfilePassword profile={profile} />
          </Route>
          <Route path="/profile/password/update" exact>
            <ProfilePasswordUpdate profile={profile} />
          </Route>
          <Route path="/profile/connect" exact>
            <ProfileStore profile={profile} />
          </Route>
        </Switch>

      </Grid>

    </div>
  );
}

ProfileMain.propTypes = {
  getProfile: PropTypes.func,
  profile: PropTypes.objectOf(PropTypes.any),
};

ProfileMain.defaultProps = {
  getProfile: () => {},
  profile: {},
};

const mapStateToProps = (state) => ({
  profile: state.profile.getProfile.profile,
  error: state.profile.getProfile.error,
  loading: state.profile.getProfile.loading,
});

const mapDispatchToProps = {
  getProfile: profileOperations.getProfile,
  clearError: profileOperations.clearError,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileMain);
