import * as types from './types';
import { apiService } from '../../middlewares';

export function orderBrandPlate() {
  return (dispatch) => apiService.post('/shop/order_brand_plate/')
    .then((response) => {
      dispatch({
        type: types.ORDER_BRAND_PLATE_SUCCESS,
        data: response.data,
      });
      return response;
    })
    .catch((error) => {
      dispatch({
        type: types.ORDER_BRAND_PLATE_FAILED,
        isLoading: false,
        error,
      });
    });
}

export function addToCart(product, amount) {
  return (dispatch) => {
    dispatch({
      type: types.ADD_TO_CART,
      payload: {
        product: { ...product, toOrder: amount },
        amount,
      },
    });
  };
}

export function removeFromCart(id) {
  return (dispatch) => {
    dispatch({
      type: types.REMOVE_FROM_CART,
      id,
    });
  };
}

export function clearCart(product, amount) {
  return (dispatch) => {
    dispatch({
      type: types.CLEAR_CART,
    });
  };
}

export function updateCart(product, amount) {
  return (dispatch) => {
    dispatch({
      type: types.UPDATE_CART,
      payload: {
        product,
      },
    });
  };
}

export function checkout(products) {
  return (dispatch) => {
    dispatch({
      type: types.CHECKOUT_CART,
      product: products,
    });
  };
}

export function samplesAddToCart(product, amount) {
  return (dispatch) => {
    dispatch({
      type: types.SAMPLES_ADD_TO_CART,
      payload: {
        product: { ...product, toOrder: amount },
        amount,
      },
    });
  };
}

export function samplesRemoveFromCart(id) {
  return (dispatch) => {
    dispatch({
      type: types.SAMPLES_REMOVE_FROM_CART,
      id,
    });
  };
}

export function samplesUpdateCart(product, amount) {
  return (dispatch) => {
    dispatch({
      type: types.SAMPLES_UPDATE_CART,
      payload: {
        product,
      },
    });
  };
}

export function samplesClearCart(product, amount) {
  return (dispatch) => {
    dispatch({
      type: types.SAMPLES_CLEAR_CART,
    });
  };
}

export function samplesCheckout(order) {
  return (dispatch) => apiService.post('/shop-product/add-sample-order/',
    {
      items: order.items,
      shipping: order.shipping,
    })
    .then((response) => {
      dispatch({
        type: types.SAMPLE_ORDER_SUCCESS,
        payload: {
          data: response.data,
        },
      });
      return response;
    })
    .catch((error) => {
      dispatch({
        type: types.SAMPLE_ORDER_FAILED,
        isLoading: false,
        error,
      });
      return error;
    });
}

export function samplesAddShipping(shipping) {
  return (dispatch) => {
    dispatch({
      type: types.CART_UPDATE_SHIPPING,
      payload: {
        shipping,
      },
    });
  };
}
