import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { productOperations } from '../../state/ducks/products';

import ProductItem from './productItem';

function CustomProductsList(props) {
  const {
    getProducts, products,
  } = props;

  useEffect(() => {
    async function fetchData() {
      await getProducts();
    }
    if (!products.length > 0) {
      fetchData();
    }
  }, []);

  return (
    <>
      {products.map((product) => (
        <Grid item xs={12} md={6} lg={3} key={product.id}>
          <ProductItem product={product} />
        </Grid>
      ))}
    </>
  );
}

CustomProductsList.propTypes = {
  getProducts: PropTypes.func,
  products: PropTypes.arrayOf,
};

CustomProductsList.defaultProps = {
  products: [],
  getProducts: () => {},
};

const mapStateToProps = (state) => ({
  products: state.products.custom_products.products.results,
});

const mapDispatchToProps = {
  getProducts: productOperations.getCustomProducts,
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomProductsList);
