import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import {
  CardElement, useElements, useStripe,
} from '@stripe/react-stripe-js';

import { useHistory } from 'react-router-dom';
import LoadingButton from '../../../components/buttons/loadingButton';
import { profileOperations } from '../../../state/ducks/profile';

function StandaloneOnboardingStepThree(props) {
  const {
    profile, updateShop, addPayment, updatePlan, getProfile,
  } = props;
  const stripe = useStripe();

  const elements = useElements();
  const [postal, setPostal] = useState('');
  const [stripeComplete, setStripeComplete] = useState('');
  const [name, setName] = useState('');
  const [loading, setLoading] = useState(false);
  const [responseError, setResponseError] = useState('');

  useEffect(() => {
    // getProfile();
  }, [profile]);

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleChange = (event) => {
    setPostal(event.target.value);
  };

  const formValid = () => {
    if (!postal || !name || !stripeComplete) {
      return false;
    }
    return true;
  };

  const handleSubmit = async (event) => {
    // Block native form submission.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    setLoading(true);

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement);

    // Use your card Element with other Stripe.js APIs
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        address: {
          postal_code: postal,
        },
        name,
      },
    });

    if (error) {
      setResponseError(error);
      setLoading(false);
    } else {
      try {
        try {
          await addPayment({ payment_id: paymentMethod.id, last_four: paymentMethod.card.last4, postal });
        } catch (err) {
          setResponseError(err.response.data.message);

          setLoading(false);
          return;
        }

        window.rewardful('convert', { email: `${profile.email}` });
        await updateShop(profile.shop[0].id, { onboarding_step: 3 });

        updatePlan(profile.shop[0].plan);

        toast.dismiss();
        setLoading(false);
      // history.push('/?onboarding_tour=standalone');
      // window.location.href = '/?onboarding_tour=standalone';
      } catch (e) {
        setLoading(false);
      }
    }
  };

  return (
    <div className="step-three-container mt-25">

      <Typography className="text--bold text__try-growth pt-10 text--georgia" variant="h4" color="secondary">Try the GROWTH PLAN</Typography>

      <Typography className="text--bold text--nunito text__try-free-stripe">14 days free!</Typography>

      <Typography className="text--nunito text__terms pb-10">Then, $29 per month</Typography>

      <div className="stripe mt-25">

        <div className="row">
          <TextField
            className="text__change-name mt-25"
            onChange={handleNameChange}
            id="outlined-basic"
            label="Name On Card"
            variant="outlined"
            required

          />
          <TextField
            className="text__change-postal mt-25 mb-25"
            onChange={handleChange}
            id="outlined-basic"
            label="Postal/ZIP Code"
            variant="outlined"
            required

          />
        </div>

        <div className="card-element-container pb-15">
          <CardElement
            onChange={(event) => setStripeComplete(event.complete)}
            options={{
              hidePostalCode: true,
              style: {
                base: {
                  fontSize: '16px',
                  color: '#424770',
                  border: '1px solid #5e5e5e',
                  '::placeholder': {
                    color: '#aab7c4',
                    marginTop: '25px',
                    maxWidth: '300px',

                  },
                },
                invalid: {
                  color: '#9e2146',
                },
              },
            }}
          />
        </div>
      </div>

      <LoadingButton disabled={!stripe || !formValid()} onClick={handleSubmit} color="primary" variant="contained" loading={loading} className="button__initial-step button__initial-step-billing">
        Sign Me Up
        {' '}
        <i className="fas fa-chevron-right" />
      </LoadingButton>

      <p className="text--error">{responseError}</p>

    </div>
  );
}

StandaloneOnboardingStepThree.propTypes = {
  profile: PropTypes.objectOf(),
  updateShop: PropTypes.func,
  addPayment: PropTypes.func,
};

StandaloneOnboardingStepThree.defaultProps = {
  profile: {},
  updateShop: () => {},
  addPayment: () => {},
};

const mapStateToProps = (state) => ({
  profile: state.profile.getProfile.profile,
});

const mapDispatchToProps = {
  getProfile: profileOperations.getProfile,
  updateShop: profileOperations.updateShop,
  updatePlan: profileOperations.updatePlan,
  addPayment: profileOperations.addPayment,
};

export default connect(mapStateToProps, mapDispatchToProps)(StandaloneOnboardingStepThree);
