import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import ClearIcon from '@material-ui/icons/Clear';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';

import { cartOperations } from '../../state/ducks/cart';
// import { productOperations } from '../../state/ducks/products';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  header: {
    borderBottom: `1px solid ${theme.palette.gray}`,
    marginBottom: '50px',
  },
  table: {
    borderCollapse: 'collapse',
    margin: 16,
    width: '50%',
    // margin: 'auto'
    minWidth: '350px',
  },
  tableData: { padding: 8 },
  colorSwatch: {
    width: '50px',
    height: '50px',
  },
}));

function SamplesCheckoutItem(props) {
  const classes = useStyles();
  const { product, removeItem, updateCart } = props;
  const [amount, setAmount] = useState(product.toOrder);
  const [cost, setCost] = useState((product.cost * product.toOrder).toFixed(2));

  const handleChange = (prop) => (event) => {
    setAmount(event.target.value);
    setCost((event.target.value * product.cost).toFixed(2));
    const updatedProduct = { ...product };

    updatedProduct.toOrder = Number(event.target.value);
    updateCart(updatedProduct);
  };

  const handleRemove = () => {
    removeItem(product.id);
  };

  return (

    <TableRow>
      <TableCell><div className={classes.colorSwatch} style={{ backgroundColor: product.color_code }} /></TableCell>
      <TableCell>{product.color_name}</TableCell>
      <TableCell>{product.name}</TableCell>
      <TableCell>{product.cost}</TableCell>
      <TableCell>
        <FormControl fullWidth className={classes.margin}>
          <InputLabel htmlFor="standard-adornment-amount">Amount</InputLabel>
          <Input
            id="standard-adornment-amount"
            value={amount}
            type="number"
            onChange={handleChange('amount')}
            startAdornment={<InputAdornment position="start" />}
          />
        </FormControl>
      </TableCell>
      <TableCell>{cost}</TableCell>
      <TableCell>
        <IconButton aria-label="delete" onClick={handleRemove}>
          <ClearIcon />
        </IconButton>
      </TableCell>

    </TableRow>

  );
}

SamplesCheckoutItem.propTypes = {
  product: PropTypes.objectOf(),
  removeItem: PropTypes.func,
  updateCart: PropTypes.func,
};

SamplesCheckoutItem.defaultProps = {
  product: {},
  removeItem: () => {},
  updateCart: () => {},
};

const mapStateToProps = (state) => ({
  items: state.cart.cart.items,
});

const mapDispatchToProps = {
  // getSupplierProducts: productOperations.getSupplierProducts,
  updateCart: cartOperations.samplesUpdateCart,
  removeItem: cartOperations.samplesRemoveFromCart,
};

export default connect(mapStateToProps, mapDispatchToProps)(SamplesCheckoutItem);
