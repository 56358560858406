import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import { productOperations } from '../../state/ducks/products';

import ProductItem from './productItem';

function SupplierProductsList(props) {
  const {
    getSupplierProducts, products,
  } = props;

  useEffect(() => {
    async function fetchData() {
      await getSupplierProducts();
    }
    if (!products.length > 0) {
      fetchData();
    }
  }, [products, getSupplierProducts]);

  return (

    <>
      {products.map((product, index) => (
        <Grid item xs={12} md={6} lg={3} key={product.id}>
          <ProductItem product={product} />
        </Grid>
      ))}
    </>
  );
}

SupplierProductsList.propTypes = {
  getSupplierProducts: PropTypes.func,
  products: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]),
};

SupplierProductsList.defaultProps = {
  products: [],
  getSupplierProducts: () => {},
};

const mapStateToProps = (state) => ({
  products: state.products.getSupplierProducts.products.results,
});

const mapDispatchToProps = {
  getSupplierProducts: productOperations.getSupplierProducts,
};

export default connect(mapStateToProps, mapDispatchToProps)(SupplierProductsList);
