import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import { toast } from 'react-toastify';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import { useHistory } from 'react-router-dom';

import { cartOperations } from '../../state/ducks/cart';
import { productOperations } from '../../state/ducks/products';

import LoadingButton from '../../components/buttons/loadingButton';

import CheckoutItem from './CheckoutItem';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  header: {
    borderBottom: `1px solid ${theme.palette.gray}`,
    marginBottom: '50px',
  },
  table: {
    borderCollapse: 'collapse',
    margin: 16,
    width: '95%',
    // margin: 'auto'
    minWidth: '350px',
  },
  tableData: { padding: 8 },
  colorSwatch: {
    width: '50px',
    height: '50px',
  },
  totalsArea: {
    textAlign: 'right',
    marginTop: '20px',
    border: 'none',
  },
  total: {
    borderTop: `1px solid ${theme.palette.gray}`,

  },
  totalsContainer: {
    border: 'none',
  },
  totalsTable: {
    width: '300px',
    marginLeft: 'auto',
    textAlign: 'right',
    marginBottom: '25px',
    marginRight: '50px',
    '& td': {
      padding: '10px 0px',
      textAlign: 'right',
    },
  },
}));

function CheckoutTable(props) {
  const classes = useStyles();
  const history = useHistory();
  const {
    items, addInventory, clearCart,
  } = props;

  const [loading, setLoading] = React.useState(false);

  const handleCheckout = (items) => {
    setLoading(true);

    const checkoutData = [];

    items.forEach((item) => {
      checkoutData.push({ id: item.id, amount: item.toOrder });
    });
    // order inventory

    addInventory({ items: checkoutData })
      .then((response) => {
        if (response.status === 200) {
          toast.success('Checkout Success');

          setLoading(false);
          clearCart();

          history.push('/success');
        } else {
          // toast.error("Checkout Error: " + response.message);
          setLoading(false);
        }

        // route to checkout success page
      });
  };

  const caclFees = (items) => {
    let total = 0;
    items.forEach((item) => {
      total += (Number(item.supplier_product.cost) * item.toOrder);
    });
    return Number((total * 0.029) + 0.3).toFixed(2);
  };

  const calcTotal = (items) => {
    let total = 0;
    items.forEach((item) => {
      total += (Number(item.supplier_product.cost) * item.toOrder);
    });

    const tempTotal = Number(total);
    const fees = Number((total * 0.029) + 0.3);
    return (tempTotal + fees).toFixed(2);
  };

  return (
    <div className={classes.root}>

      <Card className={classes.root} variant="outlined">
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="">
            <TableHead>
              <TableRow>
                <TableCell>Color</TableCell>
                <TableCell>Color name</TableCell>
                <TableCell>Product Name</TableCell>
                <TableCell>Unit Cost (USD)</TableCell>
                <TableCell>Quantity</TableCell>
                <TableCell>Total Cost (USD)</TableCell>
                <TableCell>Remove</TableCell>

              </TableRow>
            </TableHead>
            <TableBody>

              {props.items.map((product) => <CheckoutItem product={product} />)}

            </TableBody>
          </Table>
        </TableContainer>

      </Card>

      <div className={classes.totalsArea}>
        <TableContainer className={classes.totalsContainer}>
          <Table className={classes.totalsTable} aria-label="">

            <TableBody>
              <TableRow>
                <TableCell>Fees</TableCell>
                <TableCell>
                  $
                  {caclFees(items)}
                </TableCell>
              </TableRow>

              <TableRow className={classes.total}>
                <TableCell>Total</TableCell>
                <TableCell>
                  $
                  {calcTotal(items)}
                </TableCell>
              </TableRow>

            </TableBody>
          </Table>
        </TableContainer>

        <LoadingButton loading={loading} onClick={() => handleCheckout(items)} variant="outlined" color="primary" className=" button--red-outline">Checkout </LoadingButton>

      </div>
    </div>
  );
}

CheckoutTable.propTypes = {
  items: PropTypes.arrayOf,
  addInventory: PropTypes.func,
  clearCart: PropTypes.func,
};

CheckoutTable.defaultProps = {
  items: [],
  addInventory: () => {},
  clearCart: () => {},
};

const mapStateToProps = (state) => ({
  shipping: state.cart.shipping.shipping,
});

const mapDispatchToProps = {
  // getSupplierProducts: productOperations.getSupplierProducts,
  checkout: cartOperations.checkout,
  addInventory: productOperations.addInventory,
  clearCart: cartOperations.clearCart,
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutTable);
