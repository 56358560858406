import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Button from '@material-ui/core/Button';
import clsx from 'clsx';
import {useDropzone} from 'react-dropzone'


const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: 'none',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: '60%',
    display: 'flex',
    flexDirection: 'row'
  },
  img:{
    display: 'block',
    position: 'absolute',
  },
  imgContainer:{
    width: '300px',
    height: '300px' ,
    position: 'relative',
    margin: '0 auto'
  }


}));

export default function ProductLogoModal(props) {
  const classes = useStyles();  
  const {open, handleClose, product} = props;
  const [files, setFiles] = useState([]);

 
  const {getRootProps, getInputProps, isDragActive} = useDropzone({
    accept: 'image/*',  
    onDrop: acceptedFiles => {
      setFiles(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })));
    }
  })

  
  return (
    <div>
     
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={clsx(classes.modal, 'product-modal')}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>

              <div className="modalLeft">
                  
                  <div className={classes.imgContainer}>
                      <img src={product.supplier_product.image}/>

                      { files[0] &&
                          <img
                          alt="logo"
                          src={files[0]?.preview}
                          className={classes.img}
                          style={{
                            top: product.supplier_product.logo_position_y, 
                            left: product.supplier_product.logo_position_x,
                            width:  product.supplier_product.logo_size_width + 'px',
                            height: product.supplier_product.logo_size_height + 'px'
                          }}
                        />
                      }
                      
                
                  </div>
          
              </div>
              <div className="modalRight">
               
                <h3>Upload You Logo</h3>

                <ul>
                  <li>Accepted File Formats: SVG</li>
                  <li>Width: {product.supplier_product.logo_size_width}</li>
                  <li>height: {product.supplier_product.logo_size_height}</li>
                </ul>

                <div {...getRootProps()} className="dropzone">
                  <input {...getInputProps()} />
                  {
                    isDragActive ?
                      <p >Drop the files here ...</p> :
                      <p >Drag 'n' drop your logo here, or click to select</p>
                  }
                </div>
          
                <div class="modal__footer">
                
                    <Button  onClick={handleClose} size="large" variant="outlined" color="secondary" className="button--outline"  >Add To Cart</Button>
                    <Button  onClick={handleClose} size="large" variant="outlined" color="primary" className="button--red-outline">Add To Store</Button>

                </div>

              </div>
             
          </div>
        </Fade>
      </Modal>
    </div>
  );
}