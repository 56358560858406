import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';

import { profileOperations } from '../../state/ducks/profile';

function ProfileBilling(props) {
  const { profile } = props;



  return (
    <div className="profile-billing-template root">
      <Grid className="paper" container spacing={2}>

        <Card className="card" variant="outlined">

          <Typography className="text__payment-header text--bold text--georgia">Payment Information</Typography>

          <div className="paymentInfo">

            <Typography className="card__secure-message text--nunito" variant="body" color="textPrimary">
              All transactions are secure and encrypted
            </Typography>

            <Typography className="text__card-title text--nunito text--upper">Credit Card Number</Typography>

            {profile?.shop[0]?.billing_address &&
              <div className="cc-display text--nunito">
                XXXX XXXX XXXX {profile?.shop[0]?.billing_address.credit_card_digits}
              </div>
            }

            <Link className="card__update-billing-message text--nunito" to="/profile/billing/update">
              UPDATE YOUR CREDIT CARD & BILLING ADDRESS
            </Link>
          </div>
        </Card>
      </Grid>
    </div>
  );
}

ProfileBilling.propTypes = {
  profile: PropTypes.objectOf(),
};

ProfileBilling.defaultProps = {
  profile: {},
};

const mapStateToProps = (state) => ({
  profile: state.profile.getProfile.profile,
  error: state.profile.getProfile.error,
  loading: state.profile.getProfile.loading,
});

const mapDispatchToProps = {
  getProfile: profileOperations.getProfile,
  clearError: profileOperations.clearError,
  addPayment: profileOperations.addPayment,
  updateShop: profileOperations.updateShop,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileBilling);
