import PropTypes from 'prop-types';
import React, { useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { useDropzone } from 'react-dropzone';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { profileOperations } from '../../../state/ducks/profile/index.js';

import LoadingButton from '../../../components/buttons/loadingButton';

const useStyles = makeStyles((theme) => ({
  paper: {
    border: 'none',
    padding: theme.spacing(2, 4, 3),
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
}));

function ShopifyOnboardingStepTwo(props) {
  const classes = useStyles();
  const theme = useTheme();
  const {
    profile, updateShop, nextStep, loading,
  } = props;
  const [preview, setPreview] = useState();
  const [uploaded, setUploaded] = useState(false);
  const [logoLoading, setLogoLoading] = useState(false);
  const [files, setFiles] = useState();
  const maxSize = 1048576 * 2; // 2mb

  const onDrop = useCallback((acceptedFiles) => {
    setFiles(acceptedFiles[0]);

    setPreview(URL.createObjectURL(acceptedFiles[0]));
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: 'image/*',
    onDrop,
    minSize: 0,
    maxSize,
  });

  const handleNext = () => {
    updateShop(profile.shop[0].id, { onboarding_step: 2 });
    nextStep();
  };

  const handleUpload = () => {
    setLogoLoading(true);
    updateShop(profile.shop[0].id, { logo: files, logo_uploaded: true })
      .then((response) => {
        if (response.status === 200) {
          setUploaded(true);
        }
      });
  };

  const isUploaded = () => {
    if (!files) {
      return true;
    }
    return false;
  };

  return (
    <div>
      <Typography className="text__upload-logo text--georgia text--bold" color="secondary">Upload Your Logo</Typography>
      <div className={classes.paper}>

        <div className="modal-left">
          <div className="img__container">

            {!files
              && (
              <div {...getRootProps()} className="dropzone">
                <input {...getInputProps()} />
                {
                  isDragActive
                    ? <p className="text__drop-file">Drop the files here ...</p>
                    : <p className="text__drop-file">Drag 'n' drop your logo here, or click to select</p>
                }
              </div>
              )}
            {files
              && (
              <img
                alt="logo"
                src={preview}
                className="img__preview"
              />
              )}
          </div>
        </div>

        <div className="modal-right pb-10">
          <ul clasName="text--nunito">
            <li>Accepted File Formats: SVG, PNG</li>
            <li>File size limit: 2mb</li>
            <li>Max width: 1200px</li>
            <li>Max height: 1200px</li>
            <li>Logo must be 1 color</li>
          </ul>
          <LoadingButton loading={logoLoading} className={(isUploaded() || uploaded) ? 'hidden' : 'button__upload-img'} onClick={handleUpload} disabled={!files} color="primary" variant="contained">Upload</LoadingButton>

          {uploaded
            && <Typography className="text--nunito text--bold">Logo uploaded succesfully!</Typography>}
        </div>
      </div>

      <div className="actions pt-10">
        <Typography className="text__actions text--bold">Not ready to upload you logo?</Typography>
        <Typography className="text__actions">No problem! Hit "Next" and upload one later</Typography>
      </div>

      <LoadingButton loading={loading} onClick={nextStep} color="primary" variant="contained" className="button__upload-next mt-25">
        Next
        {' '}
        <i className="fas fa-chevron-right" />
      </LoadingButton>

    </div>
  );
}

ShopifyOnboardingStepTwo.propTypes = {
  profile: PropTypes.objectOf(),
  shop: PropTypes.objectOf,
  updateShop: PropTypes.func,
  nextStep: PropTypes.func,
};

ShopifyOnboardingStepTwo.defaultProps = {
  profile: {},
  shop: {},
  updateShop: () => {},
  nextStep: () => {},
};

const mapStateToProps = (state) => ({
  profile: state.profile.getProfile.profile,
  shop: state.profile.getProfile.profile.shop[0],
});

const mapDispatchToProps = {
  getProfile: profileOperations.getProfile,
  updateShop: profileOperations.updateShop,
};

export default connect(mapStateToProps, mapDispatchToProps)(ShopifyOnboardingStepTwo);
