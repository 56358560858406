import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import PropTypes from 'prop-types';
import { cartOperations } from '../../state/ducks/cart';
import { productOperations } from '../../state/ducks/products';

import CheckoutItem from './CheckoutItem';
import CheckoutTable from './CheckoutTable';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  header: {
    borderBottom: `1px solid ${theme.palette.gray}`,
    marginBottom: '50px',
  },
  table: {
    borderCollapse: 'collapse',
    margin: 16,
    width: '95%',
    // margin: 'auto'
    minWidth: '350px',
  },
  tableData: { padding: 8 },
  colorSwatch: {
    width: '50px',
    height: '50px',
  },
  totalsArea: {
    textAlign: 'right',
    marginTop: '20px',
  },
  total: {
    borderTop: `1px solid ${theme.palette.gray}`,

  },
  totalsTable: {
    width: '300px',
    marginLeft: 'auto',
    textAlign: 'right',
    marginBottom: '25px',
    '& td': {
      padding: '10px 0px',
      textAlign: 'right',
    },
  },
}));

function Checkout(props) {
  const classes = useStyles();
  const {
    error, clearError, loading, items,
  } = props;

  useEffect(() => {
    async function fetchData() {
      // await getSupplierProducts();
    }
    fetchData();
  }, []);

  const formatTime = (row) => {
    const start = moment(row.orderDeliveryTime).format('MMMM D, h:mma');
    const end = moment(row.orderDeliveryTime).add(1, 'hours').format('ha');
    return `${start} - ${end}`;
  };

  const handleCheckout = (items) => {
    const checkoutData = [];
    items.forEach((item) => {
      checkoutData.push({ id: item.id, amount: item.toOrder });
    });
  };
  const calcTotal = (items) => {
    let total = 0;
    items.forEach((item) => {
      total += (Number(item.price) * item.toOrder);
    });
    return total.toFixed(2);
  };

  return (
    <div className={classes.root}>

      {items.length > 0
        ? <CheckoutTable items={items} />
        : <h1>Cart Empty</h1>}

    </div>
  );
}

Checkout.propTypes = {
  items: PropTypes.arrayOf,
  isLoading: PropTypes.bool,
  hasErrored: PropTypes.bool,
};

Checkout.defaultProps = {
  items: [],
  isLoading: false,
  hasErrored: false,
};

const mapStateToProps = (state) => ({
  items: state.cart.cart.items,
});

const mapDispatchToProps = {
  // getSupplierProducts: productOperations.getSupplierProducts,
  checkout: cartOperations.checkout,
};

export default connect(mapStateToProps, mapDispatchToProps)(Checkout);
