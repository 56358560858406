import React from "react";
import PropTypes from 'prop-types';
import { connect } from "react-redux";

import {  makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';

import { cartOperations } from "../../state/ducks/cart";

const useStyles = makeStyles((theme) => ({

}));

function TotalsArea(props) {
    const classes = useStyles();
    const { shipping, items } = props;

   

    const caclSubTotal = (items) =>{
        var total = 0;
        items.forEach((item) => {
            total += (Number(item.cost) * item.toOrder);
        });
        return total.toFixed(2);
    }

    const caclFees = (items) =>{
        var total = 0;
        items.forEach((item) => {
            total += (Number(item.cost) * item.toOrder);
        });
        return ((total*0.029) + 0.3).toFixed(2);
    }

    const calcShipping = () => {
        var shippingCost = 0;

        if(shipping.country?.code === 'CA' || shipping.country?.code === 'US'){
            shippingCost = 6;  
            
            let itemTotal = items.reduce((a,v) =>  a = a + v.toOrder , 0 );
            shippingCost += itemTotal * 2;


        }else{
            shippingCost = 9;  
            let itemTotal = items.reduce((a,v) =>  a = a + v.toOrder , 0 );
            shippingCost += itemTotal * 3;
        }
        return shippingCost
    }

    
    const calcTotal = (items) => {
        var total = 0;
        items.forEach((item) => {
            total += (Number(item.cost) * item.toOrder);
        });


        if(shipping?.country){
            total += calcShipping()
            total = (total + (total*0.029) + 0.3);
        }else{
            total = (total + (total*0.029) + 0.3);
        }

        return total
    }



    return (
        

        <TableContainer className={classes.totalsContainer}>
            <Table className={classes.totalsTable} aria-label="">

                <TableBody>
                    <TableRow>
                        <TableCell>Subtotal</TableCell>
                        <TableCell>${caclSubTotal(items)}</TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell>Shipping</TableCell>
                        <TableCell>{shipping?.country
                            ? `$${calcShipping(shipping.country).toFixed(2)}`
                            : <span>*</span>
                        }</TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell>Taxs &amp; fees</TableCell>
                        <TableCell>${caclFees(items)}</TableCell>
                    </TableRow>
                    

                    <TableRow className={classes.total}>
                        <TableCell>Total</TableCell>
                        <TableCell>${calcTotal(items).toFixed(2)}</TableCell>
                    </TableRow>


                </TableBody>
            </Table>
        </TableContainer>
        
    );
}

TotalsArea.propTypes = {
    items: PropTypes.arrayOf,
    shipping: PropTypes.func,
}

TotalsArea.defaultProps = {
    shipping: () => {},
    items: []
};

const mapStateToProps = (state) => ({
   
    profile: state.profile.getProfile.profile,
});

const mapDispatchToProps = {
    addShipping : cartOperations.samplesAddShipping,
    clearCart: cartOperations.samplesClearCart,
    checkout: cartOperations.samplesCheckout,
};

export default connect(mapStateToProps, mapDispatchToProps)(TotalsArea);
