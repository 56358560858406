import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { orderOperations } from '../../state/ducks/orders';

import OrderItem from './orderItem';

function MyOrders(props) {
  const {
    getOrders, orders,
  } = props;

  useEffect(() => {
    async function fetchData() {
      await getOrders();
      console.log(orders);
    }
    fetchData();
  }, []);

  return (
    <div className="orders-view root">
      <Grid className="header" container spacing={2}>

        <Typography className="text--nunito text--title text--upper">Customer Orders</Typography>

      </Grid>
      <Grid className="paper" container>

        {orders.length > 0
          ? (
            <div style={{ width: '100%' }}>
              {orders.map((order) => <OrderItem order={order} />)}
            </div>
          )
          : <Typography className="text--georgia" variant="h5" color="secondary">No Orders</Typography>}

      </Grid>
    </div>
  );
}

MyOrders.propTypes = {
  getOrders: PropTypes.func,
  orders: PropTypes.objectOf,
};

MyOrders.defaultProps = {
  getOrders: () => {},
  orders: [],
};

const mapStateToProps = (state) => ({
  orders: state.orders.getOrders.orders,
  // error: state.orders.getMyOrders.error,
  // loading: state.orders.getMyOrders.loading,
});

const mapDispatchToProps = {
  getOrders: orderOperations.getOrders,
};

export default connect(mapStateToProps, mapDispatchToProps)(MyOrders);
