import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Skeleton from '@material-ui/lab/Skeleton';
// import { PRODUCT_TYPE_BLANKA_BRAND } from '../constants';

const useStyles = makeStyles((theme) => ({
  img: {
    display: 'block',
    position: 'absolute',

  },
  imgContainer: {
    position: 'relative',
    margin: '0 auto',
    // minHeight: '250px',
  },

}));

// const IMAGE_BASE = 300;

function ProductContainer(props) {
  const classes = useStyles();
  const { product } = props;
  const [loading, setLoading] = useState(true);

  // const isBlanka = () => {
  //   if (product && product.product_type === PRODUCT_TYPE_BLANKA_BRAND) {
  //     return true;
  //   }
  //   return false;
  // };

  return (

    <div className={`${classes.imgContainer} find-products__img`}>

      {loading
        && <Skeleton className="find-products__img__placeholder" variant="circle" width={150} height={150} animation="wave" />}

      <div className="product-image__wrapper">
        <LazyLoadImage
          alt={product.name}
          className="product-image"
          src={product.image}
          delayTime={0}
          height={120}
          threshold={500}
          style={{ width: '100%', maxWidth: '250px', height: 'auto' }}
          afterLoad={() => { setLoading(false); }}
        />
      </div>

    </div>

  );
}

ProductContainer.propTypes = {
  product: PropTypes.objectOf(),
};

ProductContainer.defaultProps = {
  product: {},
};

export default ProductContainer;
