import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import Logo from '../../images/blanka-stacked-white.png';
import spotifyLogo from '../../images/shopify-bag-icon.png';
import circleArrow from '../../images/circle_arrow.png';

import { toast } from 'react-toastify';

import { profileOperations } from '../../state/ducks/profile';



function ProfileStore(props) {
  const { profile, updateShop } = props;

  const [siteName, setSiteName] = useState('');

  const checker = (e) => {
    // First check if there is myshopify.com -- if there isn't, then do not continue and exit the check
    // If there is, then remove Https:// (7)
    const string = e.target.value;

    setSiteName(string);

    if (string.includes('myshopify.com')) {
      const newString = string.slice(8);
      const finalString = newString.slice(0, newString.indexOf('.'));
    }
  };

  const onSubmit = () => {

    var filteredName = siteName.replace('https://', '');

    //const regex = /myshopify.com*/i;
    //filteredName = filteredName.replace(regex, '')

    filteredName = filteredName.split('.myshopify.com')[0];

    updateShop(profile.shop[0].id, { url: `${filteredName}.myshopify.com` })
      .then(() => {

      
        if (process.env.REACT_APP_ENVIRONMENT === 'staging' ) {
          window.location.href = `https://staging-admin.blankabrand.com/shopify/login?shop=${filteredName}.myshopify.com`;
        }

        if (process.env.NODE_ENV === 'development') {
          window.location.href = `https://blanka-dev.ngrok.io/shopify/login?shop=${filteredName}.myshopify.com`;
        }
        window.location.href = `https://admin.blankabrand.com/shopify/login?shop=${filteredName}.myshopify.com`;

      }).catch((error)=>{

       
        toast.error(error.response.data?.url[0])


      });
  };


  const isDisabled = () => {
    if (siteName) {
      return true;
    }
    return false;
  };

  return (
    <div className="template-profile-store root">

      <Card variant="outlined">
        <div className="header-text-container">
          <Typography className="text__title text--georgia text--bold" color="textPrimary">
            My store
          </Typography>

          <p className="text__header">
            Manage your  ecommerce store platform intergrations.
          </p>
        </div>

        {!profile?.shop[0].store_connected
        ? (<>
        <div className="card__content">

        
          <div className="container">
            <img alt="Logo" src={Logo} className="img__blanka" />
            <img alt="Circle Arrow" src={circleArrow} className="img__circle-arrow" />
            <img alt="Shopify Logo" src={spotifyLogo} className="img__shopify" />
          </div>

          
              
                <div className="container__input">
                  <TextField id="domain-name"  label="store name" required value={siteName} onChange={(e) => checker(e)} className="domain-input-name text--nunito" />
                  {' '}
                  <Typography className="text__shopify text--nunito"> .myshopify.com </Typography>
                </div>

                <div className="container">
                  <Button disabled={!isDisabled()} onClick={onSubmit} color="primary" variant="contained" className="button text--nunito">
                    Connect
                    {' '}
                    <i className="fas fa-chevron-right" />
                  </Button>
                </div>
            
        </div>
        </>
            )
            : <div className="store-connected-wrapper">
              <Typography variant="body2">YOUR SHOPIFY STORE IS CONNECTED</Typography>

              <div className="store-connection-string">
                <img alt="Shopify Logo" src={spotifyLogo} className="shopify-icon" />
                {profile.shop[0].url}  
                
                <CheckCircleIcon></CheckCircleIcon>
               
              </div>

            </div>
        }
      </Card>
    </div>

  );
}

const mapStateToProps = (state) => ({
  profile: state.profile.getProfile.profile,
  error: state.profile.getProfile.error,
  loading: state.profile.getProfile.loading,
});

const mapDispatchToProps = {
  getProfile: profileOperations.getProfile,
  clearError: profileOperations.clearError,
  updateShop: profileOperations.updateShop,
};

ProfileStore.propTypes = {
  profile: PropTypes.objectOf,
  updateShop: PropTypes.func,
}

ProfileStore.defaultProps = {
  profile: {},
  updateShop: () => {},
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileStore);
