import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';

import AppBar from '@material-ui/core/AppBar';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import MenuIcon from '@material-ui/icons/Menu';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import {
  Link as RouteLink,
  useHistory,
} from 'react-router-dom';

import { sessionOperations } from '../state/ducks/session';

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  appBar: {
    background: theme.palette.white,
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  toolBar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'flex-end',
    },

  },
  cartIcon: {
    marginRight: '10px',
    display: 'flex',
    flexDirection: 'row',
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  actions: {
    display: 'flex',
  },
}));

function BlankaAppBar(props) {
  const classes = useStyles();
  const history = useHistory();
  const {
    logout, cartItems, sampleItems, onDrawerToggle,
  } = props;

  const handleLogout = () => {
    logout();
    // history.push('/login');
    // force a reload of the page
    window.location.href = '/login';
  };

  return (
    <AppBar position="fixed" className={classes.appBar}>

      <Toolbar className={classes.toolBar}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={onDrawerToggle}
          className={classes.menuButton}
        >
          <MenuIcon />
        </IconButton>

        <div className={classes.actions}>
          <RouteLink
            className={classes.cartIcon}
            to={{ pathname: '/checkout' }}
          >
            <ShoppingBasketIcon />

            {cartItems.length > 0
                            && (
                            <Typography>
                              (
                              {cartItems.length}
                              a)
                            </Typography>
                            )}
            {sampleItems.length > 0
                            && (
                            <Typography>
                              (
                              {sampleItems.length}
                              )
                            </Typography>
                            )}

          </RouteLink>
          <div className="account-links">

            <Link href="/profile">

              My Account
            </Link>
            <Link href="#" onClick={handleLogout}>
              Logout
            </Link>
          </div>
        </div>

      </Toolbar>
    </AppBar>
  );
}

const mapStateToProps = (state) => ({
  session: state.session.session,
  cartItems: state.cart.cart.items,
  sampleItems: state.cart.samplesCart.items,
});

const mapDispatchToProps = {
  logout: sessionOperations.logout,
};

export default connect(mapStateToProps, mapDispatchToProps)(BlankaAppBar);
