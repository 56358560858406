import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {  makeStyles } from '@material-ui/core/styles';
import { orderOperations } from "../../state/ducks/orders";
import { cartOperations } from "../../state/ducks/cart";
import {  toast } from 'react-toastify';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

import { brandPlateSuccess, NoLogoError} from "../../components/notifications"
import FreePlanLimitModal from "../../components/modals/freePlanLimitModal";
import BrandPlateImage from '../../images/brand-plate-mockup.jpg';
import LoadingButton from "../../components/buttons/loadingButton";

import {PLAN_FREE_ID} from '../../constants';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

const useStyles = makeStyles((theme) => ({
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    header:{
        borderBottom: "1px solid " + theme.palette.gray,
        marginBottom: "50px",
    },
}));

function BrandPlate(props) {
    const classes = useStyles();
    const { orderBrandPlate, profile } = props;
    const [open, setOpen] = useState(false);
    const [upgradeModalOpen, setUpgradeModalOpen] = useState(false);

  
    useEffect(() => {
        if(profile?.shop[0].plan === PLAN_FREE_ID){
            setUpgradeModalOpen(true)
        }
    }, []);
  

    const handleClickOpen = () => {
    
        if(profile?.shop[0].logo_uploaded){
            setOpen(true);
        }else{
            toast.error(NoLogoError);
        }

        console.log(profile);

        // profile.shop[0].logo
    };
    
    const handleClose = () => {
        setOpen(false);
    };

    const handleCheckout = () => {
        setOpen(false);

        orderBrandPlate()
        .then((response)=>{
            if(response && response.status==201){
                toast.success(brandPlateSuccess);
            }
        });
    }


    const isFreePlan = (profile) => {
        if(profile?.shop[0].plan==PLAN_FREE_ID){
            return true;
        }
        return false;
    }


    //<Button disabled={profile?.shop[0]?.logo_uploaded} onClick={handleClickOpen} variant="outlined" color="primary" className=" button--red-outline"  >Upload Logo</Button>
    
    return (
        <div className="brand-plate">
            <Grid className={classes.header} container spacing={2} >
                <Typography className="text__title text--upper text--nunito">My Brand Plate</Typography>
            </Grid>
            <Grid className={classes.paper} container>
                <Grid item xs={12} md={6}>
                    <img src={profile?.shop[0].brand_plate_created ? profile.shop[0].logo : BrandPlateImage} className="img"></img>
                </Grid>
                <Grid item xs={12} md={6}>
                    <div className="text--left brand-plate__content">
                        <Typography className="text__content-title text--nunito text--upper">
                            MY BRAND PLATE
                        </Typography>

                        <Typography variant="body1" className="text--bold text--nunito">
                            What's a brand plate?
                        </Typography>

                        <Typography variant="body1" className="text--nunito text--padding">
                            A brand plate is your first step to putting your logo onto products. 
                            Before you can start selling private label products, you must order a brand plate. 
                            The plate is a piece of carefully created metal with your logo cut out of it, used to etch your logo onto 
                            products.
                        </Typography>

                        <Typography variant="body1" className="text--nunito text--padding">
                            After you purchase your brand plate, the blanka team will create a mock-up for you to review. Once you have approved the brand plate, 
                            you can start selling selling your products. The only time you 
                            will need a new brand plate is if you change your logo.
                        </Typography>
                        
                        <Typography variant="body1" className="text--nunito text--padding">
                            Congrats on the first step of building your amazing brand!
                        </Typography>

                        <div className={profile?.shop[0].brand_plate_created ? "hidden" : "brand-plate__fee"}>
                            <Typography variant="h5" className="text--nunito text--padding">1 TIME FEE</Typography> 
                            <Typography variant="h4" className="text--nunito text--bold">$149 USD</Typography> 
                        </div>
                    </div>


                    {!profile?.shop[0].brand_plate_created &&
                    <div className="brand-plate__actions">

                        <Button disabled={!profile?.shop[0].billing_address||isFreePlan()} onClick={handleClickOpen} variant="outlined" color="primary" className="button button--red-outline">Order Brand Plate</Button>
                    
                        {!profile?.shop[0].billing_address && 
                            <Typography className="text__invalid-billing text--nunito" variant="body1">Please enter your billing details in the account page to order a brand plate</Typography>
                        }
                    
                    </div>
}
                </Grid>
            </Grid>
            <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">
                    {"Confirm Brand Plate Purchase"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        You will immediatly be charged $149
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <LoadingButton onClick={handleCheckout} color="primary">
                        Confirm
                    </LoadingButton>
                </DialogActions>
            </Dialog>
            
            <FreePlanLimitModal handleClose={() => setUpgradeModalOpen(false)} open={upgradeModalOpen} profile={profile}/>
        </div>
    );
}

BrandPlate.defaultProps = {
    orders: [],
    isLoading: false,
    hasErrored: false,
    profile: {}
};

const mapStateToProps = (state) => ({
    orders: state.orders.getOrders.orders,
    profile: state.profile.getProfile.profile,
    //error: state.orders.getBrandPlate.error,
    //loading: state.orders.getBrandPlate.loading,
});

const mapDispatchToProps = {
    getOrders: orderOperations.getOrders,
    orderBrandPlate : cartOperations.orderBrandPlate
};

export default connect(mapStateToProps, mapDispatchToProps)(BrandPlate);

