import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { orderOperations } from '../../state/ducks/orders';

import OrderItemInventory from './orderItemInventory';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  header: {
    borderBottom: `1px solid ${theme.palette.gray}`,
    marginBottom: '50px',
  },
  table: {
    borderCollapse: 'collapse',
    margin: 16,
    width: '50%',
    // margin: 'auto'
    minWidth: '350px',
  },
  tableData: { padding: 8 },
}));

function InventoryOrders(props) {
  const classes = useStyles();
  const {
    getOrders, orders,
  } = props;

  useEffect(() => {
    async function fetchData() {
      await getOrders();
    }
    fetchData();
  }, []);

  return (
    <div className={classes.root}>
      <Grid className={classes.header} container spacing={2}>

        <Typography variant="h6">My Inventory Orders</Typography>

      </Grid>
      <Grid className={classes.paper} container>

        {orders.length > 0
          ? (
            <>
              {orders.map((order) => <OrderItemInventory order={order} />)}
            </>
          )
          : <Typography className="text--georgia" variant="h5" color="secondary">No Orders</Typography>}

      </Grid>
    </div>
  );
}

InventoryOrders.propTypes = {
  orders: PropTypes.objectOf,
  getOrders: PropTypes.func,
};

InventoryOrders.defaultProps = {
  orders: [],
  getOrders: () => {},
};

const mapStateToProps = (state) => ({
  orders: state.orders.inventoryOrders.orders,
  // error: state.orders.getInventoryOrders.error,
  // loading: state.orders.getInventoryOrders.loading,
});

const mapDispatchToProps = {
  getOrders: orderOperations.getInventoryOrders,
};

export default connect(mapStateToProps, mapDispatchToProps)(InventoryOrders);
