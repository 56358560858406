import * as types from './types';
import * as appTypes from '../app/types';
import { apiService } from '../../middlewares';

export function getOrders() {
  return (dispatch) => {
    dispatch({ type: appTypes.IS_LOADING, payload: true });
    return apiService.get('/orders/')
      .then((response) => {
        dispatch({ type: appTypes.IS_LOADING, payload: false });
        dispatch({
          type: types.FETCH_LIST_SUCCESS,
          data: response.data,
        });
        return response;
      })
      .catch((error) => {
        dispatch({ type: appTypes.IS_LOADING, payload: false });
        dispatch({
          type: types.FETCH_LIST_FAILED,
          isLoading: false,
          error,
        });
      });
  };
}

export function getInventoryOrders() {
  return (dispatch) => {
    dispatch({ type: appTypes.IS_LOADING, payload: true });
    return apiService.get('/inventory-orders/')
      .then((response) => {
        dispatch({ type: appTypes.IS_LOADING, payload: false });
        dispatch({
          type: types.FETCH_INVENTORY_ORDERS_SUCCESS,
          payload: {
            data: response.data,
          },
        });
        return response;
      })
      .catch((error) => {
        dispatch({ type: appTypes.IS_LOADING, payload: false });
        dispatch({
          type: types.FETCH_INVENTORY_ORDERS_FAILED,
          payload: {
            isLoading: false,
            error,
          },

        });
      });
  };
}

export function updateOrder(order) {
  return (dispatch) => apiService.patch(`/orders/update/${order.id}`, order)
    .then((response) => {
      dispatch({
        type: types.UPDATE_ORDER_SUCCESS,
        data: response.data,
      });
      return response;
    })
    .catch((error) => {
      dispatch({
        type: types.UPDATE_ORDER_ERROR,
        error,
      });
    });
}
