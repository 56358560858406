import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';

import { sessionOperations } from '../../../state/ducks/session';
import { profileOperations } from '../../../state/ducks/profile';
import LoadingButton from '../../../components/buttons/loadingButton';

function StandaloneOnboardingStepOne(props) {
  const {
    createProfile, login, updateShop, getProfile,
  } = props;

  const [user, setUser] = useState({
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    accepts_terms: false,
  });

  const mediumRegex = new RegExp('^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})');

  const [loading, setLoading] = useState(false);
  const [passwordState, setPasswordState] = useState(false);
  const [error, setError] = useState('');
  const [fieldError, setFieldError] = useState();

  const handlePasswordChange = (event) => {
    setUser({ ...user, password: event.target.value });

    if (!mediumRegex.test(event.target.value)) {
      setError('Password strength too low');
      return false;
    }

    if (mediumRegex.test(event.target.value)) {
      setPasswordState(true);
      setError('');
    }
  };

  const handleSubmit = () => {
    setLoading(true);

    try {
      // create new user
      createProfile(user).then((response) => {
        // if user created successfully
        if (response.status === 201) {
          // login user in
          login({ username: user.email, password: user.password }).then((response) => {
            // adjust shop onboarding step
            getProfile().then((response) => {
              updateShop(response.data.shop[0].id, { onboarding_step: 1 })
                .then(() => {
                  setLoading(false);
                  // nextStep();
                });
            });
          });
        }
      }).catch((error) => {
        if (error.response.data.message) {
          setError(
            <NavLink to="login" className="text__error-email-used">
              {error.response.data.message}
            </NavLink>,
          );
        }

        if (error.response.data.email) {
          setFieldError({ email: error.response.data.email });
        }

        setLoading(false);
      });
    } catch (err) {
      setLoading(false);
    }
  };

  const isValid = () => {
    if (user.first_name && user.last_name && user.email && user.password && user.accepts_terms && passwordState) {
      return true;
    }

    return false;
  };

  return (
    <div className="modal__step">
      <div className="welcome-container mt-25">

        <div className="signup-container mb-10">
          <Typography className="signup-container__title text--nunito">
            Sign up for free!
          </Typography>

          <NavLink to="/login" className="signup-container__sign-in text--nunito">
            Or log in to your account
          </NavLink>
        </div>

        <form className="form pt-10">

          <TextField
            className="form-text-field form-text-field__name text--nunito"
            variant="outlined"
            name="first_name"
            required
            InputLabelProps={{ shrink: true }}
            value={user.first_name}
            label="First Name"
            onChange={(e) => setUser({ ...user, first_name: e.target.value })}
          />
          <TextField
            className="form-text-field__name form-text-field text--nunito"
            variant="outlined"
            name="last_name"
            required
            InputLabelProps={{ shrink: true }}
            label="Last Name"
            value={user.last_name}
            onChange={(e) => setUser({ ...user, last_name: e.target.value })}
          />
          <TextField
            className="form-text-field text--nunito"
            variant="outlined"
            label="Email Address"
            required
            InputLabelProps={{ shrink: true }}
            value={user.email}
            onChange={(e) => setUser({ ...user, email: e.target.value })}
            helperText={fieldError?.email}
          />
          <TextField
            className="form-text-field text--nunito"
            variant="outlined"
            label="Password"
            required
            InputLabelProps={{ shrink: true }}
            id="password"
            value={user.password}
            type="password"
            onChange={handlePasswordChange}
          />
        </form>

        <div className="terms-conditions-container">
          <Checkbox
            id="check"
            size="small"
            value={user.accepts_terms}
            className="check"
            onChange={(e) => setUser({ ...user, accepts_terms: e.target.value })}
          />
          <a target="__blank" href="https://blankabrand.com/blanka-terms-and-conditions/" className="terms-text text--nunito">
            Yes, I agree to blanka's Terms and Conditions
          </a>
        </div>
      </div>

      <div>
        <LoadingButton disabled={!isValid()} loading={loading} onClick={handleSubmit} color="primary" variant="contained" className="button__initial-step">
          Sign Me Up
          {' '}
          <i className="fas fa-chevron-right" />
        </LoadingButton>

        <p className="text__error">{error || <a> </a>}</p>
        <p className="text__error">{}</p>

      </div>

    </div>
  );
}

StandaloneOnboardingStepOne.propTypes = {
  createProfile: PropTypes.func,
  login: PropTypes.func,
  updateShop: PropTypes.func,
  getProfile: PropTypes.func,
};

StandaloneOnboardingStepOne.defaultProps = {
  createProfile: () => {},
  login: () => {},
  updateShop: () => {},
  getProfile: () => {},
};

const mapStateToProps = (state) => ({
  session: state.session.session,
  profile: state.profile.getProfile.profile,
  profileLoading: state.profile.getProfile.loading,
});

const mapDispatchToProps = {
  createProfile: profileOperations.createProfile,
  updateShop: profileOperations.updateShop,
  getProfile: profileOperations.getProfile,
  login: sessionOperations.login,
  logout: sessionOperations.logout,
};

export default connect(mapStateToProps, mapDispatchToProps)(StandaloneOnboardingStepOne);
