import React from "react";
import { connect } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { profileOperations } from "../../state/ducks/profile";
import Button from '@material-ui/core/Button';
import { toast } from 'react-toastify';
import Modal from '@material-ui/core/Modal';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import clsx from 'clsx';
import CardContent from '@material-ui/core/CardContent';

import { SHOP_ONBOARDING_SHOPIFY } from '../../constants';
import {notifications } from '../../components/notifications';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
    backgroundColor: theme.palette.background.paper,
        border: 'none',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: '80%',
        maxWidth: '1000px',
        display: 'flex',
        flexDirection: 'column'
    },
}));

function ChangePlanModal(props) {
    const classes = useStyles();
    const { getProfile, profile, updatePlan, open, handleClose } = props;



    const handleFree = (id) => {
        
        /*
        if(!profile.shop[0].billing_address){
            notifications.noBillingMethodError();
            return;
        }*/
        
        updatePlan(id)
        .then((response) => {
            getProfile()
            .then((response)=>{
                toast.success("Success! Your account has been updated");
                handleClose();
            });
        }); 
    };

    const handlePaid = (id) => {

        if(!profile.shop[0].billing_address){
            notifications.noBillingMethodError();
            return;
        }

        updatePlan(id).then((response)=>{
            
            if(response && response.status === 200 && profile.shop[0] && profile.shop[0].shop_signup_type === SHOP_ONBOARDING_SHOPIFY ){
                window.location.href = response.data.confirmation_url;
            }else if(response && response.status === 200 && profile.shop[0]){
                //TODO: check to see if upgraded or downgraded
                getProfile().then(response=>{
                    toast.success("Success! Your account has been updated");
                    handleClose();
                });
            }


        });       
    }

    const isPlan = (id ) =>{
        if(id === profile?.shop[0].plan){
            return true
        }
        return false;
    }

    return (
        <div>
            <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="Plan Modal"
            aria-describedby="Pick your plan"
            className={clsx(classes.modal, 'onboarding-modal', 'change-plan-modal')}
            >

                <div className={clsx(classes.paper, 'change-plan-modal-wrapper')}>
                    <div>
                        <Typography onClick={handleClose} className="close-button modal__close modal__close--change-plan">[x] close</Typography>
                    </div>

                    <div>
                        <Typography className="welcome text--georgia text--bold" color="secondary">Pick Your Plan </Typography>
                    </div>

                    <div className="model-content">
                        <div className="plans">
                            <Card className={"payment_price_item mb-10" +  (isPlan(1) ? 'center' : '')}>
                                <CardContent >
                                    <Typography variant="h4" className="text--center text--georgia-bold" color="textSecondary" gutterBottom>
                                        $0/mo
                                    </Typography>
                                    <Typography variant="h5" className="text--center text--georgia-bold" color="textSecondary" gutterBottom>
                                        FREE PLAN
                                    </Typography>

                                    <ul>
                                        <li>0 private label products</li>
                                        <li>10 unbranded products</li>
                                    </ul>                   
                                </CardContent>
                                <CardActions>
                                    <Button disabled={isPlan(1)} onClick={()=>handleFree(1)} color="primary" variant="contained"  className="button">
                                        
                                        {isPlan(1) ? 'Current' : 'Get Started'}
                                        {' '} <i className="fas fa-chevron-right" />
                                        </Button>
                                </CardActions>
                            </Card>

                            <Card className={"payment_price_item mb-10" +  (isPlan(2) ? 'center' : '')}>
                                <CardContent>
                                    <Typography variant="h4" className="text--center text--georgia-bold" color="textSecondary" gutterBottom>
                                        $29/mo
                                    </Typography>
                                    <Typography variant="h5" className="text--center text--georgia-bold" color="textSecondary" gutterBottom>
                                        GROWTH PLAN
                                    </Typography>
                                
                                    <ul>
                                        <li>10 private label products</li>
                                        <li>Unlimited unbranded products</li>
                                        <li>Free product mock-ups</li>
                                    </ul>
                                </CardContent>
                                <CardActions>
                                    <Button disabled={isPlan(2)} onClick={()=>handlePaid(2)} color="primary" variant="contained" className="button">
                                            {isPlan(2) ? 'Current' : 'Try For Free'}
                                            {' '} <i className="fas fa-chevron-right" />
                                        </Button>
                                </CardActions>
                            </Card>

                            <Card className={"payment_price_item mb-10" +  (isPlan(3) ? 'center' : '')}>
                                <CardContent>
                                <Typography variant="h4" className="text--center text--georgia-bold" color="textSecondary" gutterBottom>
                                        $59/mo
                                    </Typography>
                                    <Typography variant="h5" className="text--center text--georgia-bold " color="textSecondary" gutterBottom>
                                        SCALE PLAN
                                    </Typography>
                                    
                                    <ul>
                                        <li>Unlimited private label products</li>
                                        <li>Unlimited unbranded products</li>
                                        <li>Free product mock-ups</li>
                                        <li>Dedicated account manager</li>
                                    </ul>
                                </CardContent>

                                <CardActions>
                                    <Button disabled={isPlan(3)} onClick={()=>handlePaid(3)} color="primary" variant="contained" className="button">
                                        {isPlan(3) ? 'Current' : `Try For Free `}
                                        {' '} <i className="fas fa-chevron-right" />
                                    </Button>
                                </CardActions>
                            </Card>
                            </div>
                        </div>
                </div>
            </Modal>
        </div>
    )
}

ChangePlanModal.defaultProps = {
    profile: {},
    shop: {}
};


const mapStateToProps = (state) => ({
    profile: state.profile.getProfile.profile,
});

const mapDispatchToProps = {
    getProfile: profileOperations.getProfile,
    updateShop: profileOperations.updateShop,
    updatePlan: profileOperations.updatePlan,
};


export default connect(mapStateToProps, mapDispatchToProps)(ChangePlanModal);
