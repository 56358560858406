import React, { useState} from "react";
import { connect } from "react-redux";

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';

import { profileOperations } from "../../state/ducks/profile";
import ChangePlanModal from './changePlanModal';
import AddLogoModal from '../../components/modals/addLogoModal'

function ProfilePlan(props) {
    const { getProfile, profile } = props;
    const [planModal, setPlanModal] = useState(false);
    const [logoModal, setLogoModal] = useState(false);

    const handleOpen = () => {
        setPlanModal(true);
    };

    const handleClose = () => {
        setPlanModal(false);
    };

    const handleOpenLogoModal = () => {
        setLogoModal(true);
    };

    const handleCloseLogoModal = () => {
        setLogoModal(false);
        getProfile();
    };
   
    return (
        <div className="template-profile root">

            <Card className="card" variant="outlined">
                
                <div className='card__left'>
                    <Typography className="card__title text--nunito text--bold text--upper" variant="h4">
                        Welcome back, {profile?.name}
                    </Typography>
                    <Typography className="text--bold text--upper" color="secondary" variant="h6">
                        {profile?.email}
                    </Typography>
                    <Typography className="card__plan-title text--georgia text--bold" color="textPrimary">
                        Your Plan: {' '} 
                        <span className="text--gray">
                            {profile?.shop[0].plan_name}
                        </span>
                    </Typography>
                

                    <Button onClick={handleOpen} color="primary" variant="contained" className="button text--nunito button__change-plan">Change Plan</Button>

                </div>
                <div className="card__right">

                    <Typography className="card__text-logo text--georgia-bold" variant="h4" color="textPrimary">Your Logo</Typography>

                    <img className="logo" src={profile?.shop[0].logo}/>
                
                    <Button onClick={handleOpenLogoModal} variant="outlined" color="primary" className="button--red-outline card__update-logo-button text--nunito">Update Your Logo</Button>
                    
                </div>
            
            </Card>
    
            <ChangePlanModal handleClose={handleClose} open={planModal} />

            <AddLogoModal profile={profile} handleClose={handleCloseLogoModal} open={logoModal} />

        </div>
        
    )
}

const mapStateToProps = ( state ) => ( {
    profile: state.profile.getProfile.profile,
    error: state.profile.getProfile.error,
    loading: state.profile.getProfile.loading,
} );

const mapDispatchToProps = {
    getProfile: profileOperations.getProfile,
    clearError: profileOperations.clearError
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePlan);
