import React, { useEffect, useState } from 'react';
import Card from '@material-ui/core/Card';
import PropTypes from 'prop-types';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';

import { PRODUCT_TYPE_UNBRANDED } from '../../constants';

function ProductForm(props) {
  const { product } = props;
  const [price, setPrice] = useState(0);
  const handleChange = (prop) => (event) => {
    setPrice(event.target.value);
  };

  const calcProfit = (cost, price) => (price - cost).toFixed(2);

  useEffect(() => {
    setPrice(product.supplier_product.suggested_cost);
  }, [product]);

  const isUnbranded = () => {
    if (product && product.supplier_product.product_type === PRODUCT_TYPE_UNBRANDED) {
      return true;
    }
    return false;
  };

  return (
    <Card className="product-form-card root" variant="outlined">

      <TableContainer component={Paper}>
        <Table aria-label="">
          <TableHead>
            <TableRow>
              <TableCell className="text--nunito">Color</TableCell>
              <TableCell className="text--nunito text--center">Color name</TableCell>
              <TableCell className="text--nunito text--center">Unit Cost (USD)</TableCell>
              <TableCell className="text--nunito text--center">Suggested MSRP (USD)</TableCell>
              <TableCell className="text--center text--bold text--peach">Your Profit</TableCell>
              <TableCell className={isUnbranded() ? 'hidden' : 'inventory-cell text--nunito'} align="center">Inventory</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell><div className="color-swatch" style={{ backgroundColor: product.supplier_product.color_code }} /></TableCell>
              <TableCell className="text--center text--spacing-15 text--upper text--nunito">{product.supplier_product.color_name}</TableCell>
              <TableCell className="text--center text--nunito">
                $
                {' '}
                {' '}
                {product.supplier_product.cost}
              </TableCell>
              <TableCell align="center">
                <FormControl fullWidth className="pb-10">
                  <InputLabel className="text--nunito" htmlFor="standard-adornment-amount" />
                  <Input
                    id="standard-adornment-amount"
                    value={price}
                    type="number"
                    disableUnderline
                    className=" input text--nunito text--center"
                    onChange={handleChange('amount')}
                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                  />
                </FormControl>

              </TableCell>
              <TableCell className="text__color-name text--center text--bold text--nunito text--peach" align="center">
                $
                {' '}
                {' '}
                {calcProfit(product.supplier_product.cost, price)}
              </TableCell>
              <TableCell className={isUnbranded() ? 'hidden' : 'inventory-cell text--bold'} align="center">{product.inventory}</TableCell>

            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
}

ProductForm.propTypes = {
  product: PropTypes.objectOf(PropTypes.any),
};

ProductForm.defaultProps = {
  product: {},
};

export default ProductForm;
