import React from 'react';
import { connect } from "react-redux";
import { productOperations } from "../../state/ducks/products";
import {  toast } from 'react-toastify';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import { cartOperations } from "../../state/ducks/cart";
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import {PRODUCT_TYPE_SUPPLIER, PRODUCT_TYPE_UNBRANDED,PRODUCT_TYPE_CUSTOM } from '../../constants';

import {addToStoreSuccess, SampleWithInventoryError, addSampleToCartSuccess} from '../../components/notifications'

import ProductContainer from "../../components/productContainer"
import ProductModal from './productModal';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    
    backButton: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    img:{
      display: 'block',
      position: 'absolute',
    },
    imgContainer:{
      width: '300px',
      height: '300px' ,
      position: 'relative',
      margin: '0 auto'
    }
  
 
}));


function ProductItem(props) {
    const classes = useStyles();
    const {product, addToStore, profile, samplesAddToCart, cart} = props;
    const [open, setOpen] = React.useState(false);

   
    const handleOpen = () => {
      setOpen(true);
      console.log(product)
    };
   
    const handleClose = () => {
      setOpen(false);
    };


    const handleAddToStore = (id) => {
     
      addToStore(id)
      .then((response)=>{
        handleClose();
        
        if(response && response.status==200){
          toast.success(addToStoreSuccess);
        }

      });
    }
    

    const handleSampleOrder = () => {
    
      if(cart.cart.items.length>0){
        toast.error(SampleWithInventoryError);
      }else{
        samplesAddToCart(product, 1)
        toast.success(addSampleToCartSuccess);
        handleClose();
      }
      
    }

    const getTag = () =>{
      
      if(product && product.product_type === PRODUCT_TYPE_SUPPLIER ){
        return <div className="tag tag--blanka">Private Label</div>;
      }else if(product && product.product_type === PRODUCT_TYPE_UNBRANDED){
        return <div className="tag tag--unbranded">Unbranded</div>;
      }else if(product && product.product_type === PRODUCT_TYPE_CUSTOM){
        return <div className="tag tag--custom">Custom Product</div>
      }

      return false;
    }

    const expiringSoon = () => {
      if(product.is_expiring) {
        return <div className="tag tag--expiring-soon">Product Expiring Soon</div>
      }

      return false;
    }

    return (
      <div>
        <Card className={classes.root, 'supplier-card'} variant="" onClick={handleOpen}>

          <div className="tag-container">
            {getTag()}
            {expiringSoon()}
          </div>
    
          <CardContent>
              <Typography variant="h6" color="secondary" className={`product__title ${product.is_expiring ? "mt-32" : ""}`} >{product.name} &#xb7; {product.color_name}</Typography>
              <ProductContainer logo={profile?.shop[0].logo} product={product}/>      
          
          </CardContent>
          <CardActions>
            <Button  onClick={handleOpen} size="large" className="action-button">View Details</Button>
          </CardActions>
        </Card>
        <ProductModal profile={profile} onClose={handleClose} handleSampleOrder={handleSampleOrder} product={product} handleClose={handleClose} onAddToStore={handleAddToStore}  open={open} />
      </div>
    );
};


ProductItem.defaultProps = {
  product: {}
};

const mapStateToProps = (state) => ({
  profile: state.profile.getProfile.profile,
  cart: state.cart,
  samplesCart: state.cart.samplesCart
});

const mapDispatchToProps = {
  addToStore: productOperations.addToStore,
  samplesAddToCart: cartOperations.samplesAddToCart
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductItem);

